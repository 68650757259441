import authRquest from '../helpers/authorizateRequest'
 

export default {
    get: async(payload, url, param) => {
        let _param = '';
        if( payload && payload.length ) {
            _param = payload.reduce((a,b)=>{ return a+='/'+b }) // return /param/param2/param3 ... 
            _param = _param ? '/'+_param : ''
        } else {
            _param = new URLSearchParams(payload).toString(); // return param1=something&param2=somethingelse ... 
            _param = _param ? '?'+_param : ''
        }

        param = param || ''
        const endpoint = url || DEFAULT_ENDPOINTS.dashboard
        const { data } = await authRquest.get(endpoint + _param + param )
        return data
    },
    put: async(_data,url) => {
        const endpoint = url
        const { data } = await authRquest.put( endpoint, _data)
        return data
    },
    post: async(_data,url) => {
        const endpoint = url
        const { data } = await authRquest.post( endpoint, _data)
        return data
    },
    delete: async(url) => {
        const endpoint = url
        const { data } = await authRquest.delete( endpoint)
        return data
    },
}