<template>
    
    <div class="tel-list__item" v-if="value" style="height: auto;">
        <div class="tel-list__name">
            {{ timerTime | formatTime }}
            <div v-if="value.audio">
                <audio ref="audio" v-if="value.audio" :id="'peervideom' + value.plugin.id" autoplay @canplaythrough="handleAudioReady" />
            </div>

            <template v-if="value.company">
                <svg v-if="value.company.settings.can_change_company" width="15" height="15" :style="{marginLeft: '5px'}">
                    <use href="#icon-link"></use>
                </svg>
                {{ value.company.name }}
            </template>
        </div>
        <div class="tel-list__number" :class="{'tel-list__number-centered': value.client.name == 'неизвестный'}">
            {{ value.number }}
            <div class="tel-list__buttons">
                <template v-if="value.audio">  
                    <button class="button button_with_icon button_type_icon" 
                        :class="{
                            'button_color_transp-yellow': dtmf.isShow,
                            'button_color_transp-gray': !dtmf.isShow
                    }"
                        :style="{marginRight: '24px'}"
                        @click="dtmf.isShow = !dtmf.isShow"
                    >
                        <svg aria-hidden="true" width="9" height="16">
                            <use href="#icon-terminal"></use>
                        </svg>
                    </button>
                    
                    <button class="button button_with_icon button_type_icon" 
                        :class="{
                            'button_color_transp-yellow': showTransfer,
                            'button_color_transp-gray': !showTransfer
                    }"
                        :style="{marginRight: '24px'}"
                        @click="showTransfer = !showTransfer"
                    >
                        <svg aria-hidden="true" width="9" height="16">
                            <use href="#icon-call-transfer"></use>
                        </svg>
                    </button>

                    <!-- <button class="button button_with_icon button_type_icon" 
                        :class="{
                            'button_color_transp-black': isVolume,
                            'button_color_transp-gray': !isVolume
                    }"
                        :style="{marginRight: '24px'}"
                        @click="handleChangeVolume"
                    >
                        <svg aria-hidden="true" width="9" height="16">
                            <use href="#icon-headphones"></use>
                        </svg>
                    </button> -->

                    <button class="button button_with_icon button_type_icon" 
                        :class="{
                            'button_color_transp-black': !this.value.audio.isMute,
                            'button_color_transp-gray': this.value.audio.isMute
                    }"
                        :style="{marginRight: '24px'}"
                        @click="handleMuted"
                    >
                        <svg aria-hidden="true" width="9" height="16">
                            <use href="#icon-microphone"></use>
                        </svg>
                    </button>
                </template>


                <button class="tel-list__button-red button button_with_icon button_type_icon button_color_transp-black" @click="handleHangup">
                    <svg aria-hidden="true" width="30" height="30">
                        <use href="#icon-phone-denay-circle"></use>
                    </svg>
                </button>
            </div>
        </div>
        <div class="tel-list__number" :style="{marginTop: '20px', alignItems: 'center'}" v-if="showTransfer">
            <v-custom-input class="size_medium width__full"
                placeholder="введите номер"
                v-model="phoneNumber"
            />
            <button class="tel-wind__call button button_with_icon button_type_icon button_color_transp-black" 
                @click="handleTransfer"
                type="submit"
                :disabled="validate"
                :style='{opacity: validate ? .5 : "" }'
            >
                <!-- :disabled="value.contact.transferring"
                :style="{opacity: value.contact.transferring ? '.3' : '1'}" -->
                <svg aria-hidden="true" width="30" height="30">   
                    <use href="#icon-transfer-circle"></use> 
                </svg>
            </button>
        </div>
        

        <div class="tel-list__number" :style="{marginTop: '20px', alignItems: 'center'}" v-if="dtmf.isShow">
            <v-custom-input class="size_medium width__full"
                placeholder="введите dtmf"
                v-model="dtmf.value"
                ref="dtmfInput"
                @input="handlInputDTMF"
                @keyup="handlKeyUpDTMF"
            />
        </div>


    </div>
</template>

<script>
import vCustomInput from '@components/global/CustomInput'
import vButton from '@components/global/vButton'
import api from '@api'
import { eventBus } from '@eventBus'
export default {
    components: {
        vCustomInput,
        vButton
    },
    props: {
        value: {
            type: Object,
            default: () => {}
        },
        // currentNumber: {
        //     type: String,
        //     default: ''
        // },
        isValidate: {
            type: Boolean,
            default: false
        },
        agentName: {
            type: String,
            default: ''
        }
    },
    computed: {
        validate() {
            return !this.phoneNumber || this.phoneNumber == this.value.client.number || this.phoneNumber == this.agentName
        }
    },
    data() {
        return {
            isMute: false,
            showTransfer: false,
            phoneNumber: '',
            audio: null,
            timer: null,
            timerTime: 0,
            isVolume: true,
            dtmf: {
                timer: null,
                value: null,
                oldValue: null,
                isShow: false
            }
        }
    },
    mounted() {
        eventBus.$emit('vJanusAcceptIncoming', this.value);
    },
    filters: {
        formatTime(milliseconds)  {
            // Переводим миллисекунды в секунды и округляем до целого числа
            let totalSeconds = Math.floor(milliseconds);

            // Вычисляем часы, минуты и секунды
            let hours = Math.floor(totalSeconds / 3600);
            let minutes = Math.floor((totalSeconds % 3600) / 60);
            let seconds = totalSeconds % 60;

            // Форматируем значения, добавляя нули перед однозначными числами
            let formattedHours = ('0' + hours).slice(-2);
            let formattedMinutes = ('0' + minutes).slice(-2);
            let formattedSeconds = ('0' + seconds).slice(-2);

            // Собираем итоговую строку в формате "чч:мм:сс"
            let hourse = hours ? `${formattedHours}:` : '';
            let formattedTime = hourse+`${formattedMinutes}:${formattedSeconds}`;

            return formattedTime;
        }
    },
    methods: {
        handleChangeVolume() {
            if(this.$refs.audio) {
                this.isVolume = !this.isVolume
                this.$refs.audio.volume = this.isVolume ? 1 : 0
            }
        },
        handleAudioReady() {
            if(this.timer) clearInterval(this.timer);
            this.timer = setInterval(() => {
                const audio = this.$refs.audio
                if(!audio) {
                    clearInterval(this.timer);
                    return
                }
                this.timerTime = ++this.timerTime; // Получаем текущее время в секундах
                this.value.audio.timer = this.timerTime;
            }, 1000); 
        },
        handleTransfer() {
            const plugin = this.value.plugin
            this.$emit('action', {action:'transfer', plugin, number: this.phoneNumber})
        },
        handleHangup() {
            clearInterval(this.timer);
            this.handleMuted()
            this.handleChangeVolume()

            const plugin = this.value.plugin

            plugin.send({
                message: { request: "hangup"},
                success: () => {
                    console.log('Успешное завершение вызова');
                },
                error: (error) => {
                    console.error('Ошибка при завершении вызова:', error);
                    this.$toast.error('Ошибка при завершении вызова:', this.value.client.number);
                }
            });
        },
        handleMuted() {
            if(this.value.audio.isMute) {
                this.value.unmuteAudio()
            } else {
                this.value.muteAudio()
            }
            
        },
        handlKeyUpDTMF(value, event) {
            const key = event.key;
            const deleteKeys = ['Backspace', 'Delete'];
            this.dtmf.value = this.dtmf.value.replace(/[^+\d*]|(?!^)\+/g, '')
            console.log(key,event.keyCode, deleteKeys.includes(key),this.dtmf);
            if (deleteKeys.includes(key)) {
                if(this.dtmf.oldValue && this.dtmf.value.length <= this.dtmf.oldValue.length) this.dtmf.value = this.dtmf.oldValue
            }
        },

        handlInputDTMF(value,event) {
            const time =  1300
            if(this.dtmf.oldValue && this.dtmf.oldValue.length >= this.dtmf.value.length ) return
            if(this.dtmf.timer) clearTimeout(this.dtmf.timer)
            this.dtmf.timer = setTimeout(() => {
                const dtmfString = this.dtmf.value.replace(this.dtmf.oldValue,'') 
                this.dtmf.oldValue = ''+this.dtmf.value
                const plugin = this.value.plugin
                console.log('handlInputDTMF', {plugin, dtmfString});
                plugin.dtmf({
                    dtmf: {tones: ''+dtmfString},
                    success: (message) => {
                        console.log('Успешное dtmf:', {plugin}, this.value.client.number);
                    },
                    error: (error) => {
                        console.error('Ошибка dtmf:', error);
                    }
                });
            }, time);
        }
    }
}
</script>

<style lang="scss">

</style>
