import Janus from 'janus-gateway'
import adapter from 'webrtc-adapter'
import log from './customLog.js'

export default class  {
    constructor(options) {
        this.cache(options)
        this.init()
    }
                
    cache(options) {
        this.settings = {
            ...this.defaults,
            ...options
        }
        this.settings.helpersCount=0
        this.settings.helpers=[]
        this.settings.master_id = null
        this.janus = null
        this.console = new log({name: 'janus'})
        this.console.log('settings', this.settings)
    }
                
    get defaults() {
        return {
            telSettings: {                
                id: "781",
                ip: "89.184.65.45",
                password: "7EM2y7GVXZnoJmSJ",
                port: "5060",
                wss_url: "wss://pbx.fleetsoft.pro/ws",
            },
        }
    }
                
    init() {
        if(this.settings.telSettings) {
            this.janusInit()
            //this.events()
        } else {
            this.console.error('init: не все данные', this.settings.telSettings)
            if(this.settings.registrationFailed) this.settings.registrationFailed('не все данные')
        }
        return this
    }

    janusInit() {
        const {ip, port, id, password} = this.settings.telSettings
        Janus.init({
            //debug: "all",
            dependencies: Janus.useDefaultDependencies({ adapter: adapter }),
            callback: () => {
                this.connect(this.settings.telSettings.wss_url)
            }
        })
    }

    connect(server) {
        this.janus = new Janus({
            server: server,
            iceServers: null,
            success: () => {
                this.attachPlugin()
            },
            error: (error) => {
                console.log('[janus2] Failed to connect to janus server', error)
            },
            destroyed: () => {
                //window.location.reload()
            }
        })
    }

    attachPlugin() {
        this.plugin = null
        this.janus.attach({
            plugin: "janus.plugin.sip",
            opaqueId: this.settings.telSettings.id,
            success: (plugin) => {
                this.plugin = plugin
                const register = this.getRegister()
                this.plugin.send(register)
            },
            onmessage: (msg, jsep) => {
                this.onMessage(this.plugin, {msg, jsep}, 'master')
            },
            onremotetrack: (track, mid, on, meta) => {
                this.onremotetrack(this.plugin,{track, mid, on, meta})                
            },
            onremotestream: (stream) => {
                this.onRemoteStream(stream)
            },
            oncleanup: () => {
                //console.log('oncleanup')
            },
            error: function (error) {
                console.error(error)
            },
        })        
    }

    onremotetrack(plugin, obj) {
        const {track, mid, on, meta} = obj
        console.log({track, mid, on, meta});
        if (track.kind === "audio") {
            let stream = new MediaStream([track]);
            const audio = {stream, mid}
            const id = plugin.id

            if(this.settings.addAudio) this.settings.addAudio(id, audio)
            setTimeout(() => {
                const _audio = document.querySelector('#peervideom'+id)
                console.log('attachMediaStream', _audio, stream);
                Janus.attachMediaStream(_audio, stream);
            }, 1);
        }
    }

    onMessage(plugin, obj, janusid) {
        
        const {jsep,msg} = obj
        const callId = msg["call_id"];
        const result = msg["result"] || {};
        if (!result) {
            console.error('empty result', {obj});
            return
        }

        const message = result["event"] || '';

        // if(message == 'message' || message =='info' ) {
        //     const sender = result["displayname"] ? result["displayname"] : result["sender"];
        //     let content = result["content"];
        //     content = content.replace(new RegExp('<', 'g'), '&lt');
        //     content = content.replace(new RegExp('>', 'g'), '&gt');
        // }

        if (message) {
            
            switch (message) {
                case "registering":
                    break
                case "registered":
                    break
                case "registration_failed":
                    break
                case "calling":
                    break
                case "incomingcall":
                    plugin.isbizzy = true
                    if(!this.hasFreeHelper()) {
                        this.addHelper()
                    }
                    this.console.log('helpers.length' + this.settings.helpers.length, this.settings.helpers)
                    break
                case 'accepting': 
                    break
                case 'progress': 
                    if (jsep) {
                        plugin.handleRemoteJsep({
                            jsep: jsep,
                            error: (error) => {
                                console.error('progress handleRemoteJsep', error)
                            }});
                        }
                    break
                case 'accepted':
                    if (jsep) {
                        plugin.handleRemoteJsep({
                            jsep: jsep, 
                            error: (error) => {
                                console.error('handleRemoteJsep', error)
                            }
                        });
                    }
                    break
                case 'updatingcall': 
                    const _this = this
                    this.plugin.createAnswer({
                        jsep: jsep, 
                        tracks: [{type: 'audio', capture: true, recv: true}], 
                        success: function (jsep) {
                            let body = {request: "update"};
                            _this.plugin.send({message: body, jsep: jsep});
                        }, error: function (error) {
                            console.error(error)
                        }
                    });
                    break
                case 'message':
                    break
                case 'info':
                    break
                case 'notify': 
                    break
                case 'transferring':
                    break
                case 'hangup':
                    plugin.isbizzy = false
                break
            }

            switch (message) {
                case "registered":
                    this.settings.telSettings.master_id  = result["master_id"];
                    if(this.settings.registered) this.settings.registered()
                    this.console.success(`registered [${janusid}]: ${message}`, {msg, jsep}, {plugin});
                break
                case "registration_failed":
                    if(this.settings.registrationFailed) this.settings.registrationFailed(msg.result.reason)
                    this.console.error(`onMessage [${janusid}]: ${message} ` + msg.result.reason, {msg, jsep}, {plugin});
                break
                default:
                    this.console.log(`onMessage [${janusid}]: ${message}`, {msg, jsep}, {plugin});
                    this.settings.emitMessage(message, plugin, {msg, jsep})
                break
            }
        }
    }
    getRegister() {
        const {ip, port, id, password} = this.settings.telSettings
        const sipserver = "sip:"+id+"@" + ip + ":" + port
        const username = "sip:"+id+"@" + ip 

        const register = {
            request: "register", username: username
        };
        register["secret"] = password;
        register["proxy"] = sipserver;
        return {message: register}
    }

    addHelper(helperCreated) {
        helperCreated = (typeof helperCreated == "function") ? helperCreated : Janus.noop;
        const _this = this
        const helpersCount = this.settings.helpersCount++;
        const helperId = helpersCount;
        this.settings.helpers[helperId] = {
            id: helperId, localTracks: {}, localVideos: 0, remoteTracks: {}, remoteVideos: 0
        };

        return new Promise((resolve, reject) => {
            this.janus.attach({
                plugin: "janus.plugin.sip",
                opaqueId: this.settings.opaqueId,
                success: function (pluginHandle) {
                    _this.settings.helpers[helperId].sipcall = pluginHandle;
                    const message = "[Helper #" + helperId + "] Plugin attached! (" + _this.settings.helpers[helperId].sipcall.getPlugin() + ", id=" + _this.settings.helpers[helperId].sipcall.getId() + ")"
                    const {id, master_id} = _this.settings.telSettings
                    const username = id
                    _this.settings.helpers[helperId].sipcall.send({
                        message: {
                            request: "register", 
                            type: "helper", 
                            username,	// We use the same username as the master session
                            master_id				// Then we add the ID of the master session, nothing else
                        }
                    });
                    _this.settings.helpers[helperId].sipcall.isbizzy = false
                    _this.console.log(message, {pluginHandle, helperId, helpersCount, message, master_id, username})
                    resolve(helperId);
                },
    
                error: function (error) {
                    const message = "[Helper #" + helperId + "]   -- Error attaching plugin..."+ error
                    _this.console.error(message, error)
                },
                onmessage: function (msg, jsep) {
                    let error = msg["error"];
                    if (error) {
                        console.error(error);
                        const message = "[Helper #" + helperId + "]"
                        _this.console.error(message, error)
                        return;
                    }
                    _this.onMessage(_this.settings.helpers[helperId].sipcall, {msg, jsep}, helperId)
                },
                onremotetrack: function (track, mid, on) {
                    const message = "[Helper #" + helperId + "] Remote track (mid=" + mid + ") " + (on ? "added" : "removed") + ":"+ track
                    const plugin = _this.settings.helpers[helperId].sipcall
                    _this.onremotetrack(plugin,{track, mid, on})
                },
            })
        });
    }

   async getFreeHelper() {
        
        let helperId = this.hasFreeHelper() 
        if(helperId === false) {
            await this.addHelper().then((_helperId) => {
                helperId = _helperId
            })
        }
        return this.settings.helpers[helperId]
    }

    hasFreeHelper() {
        if(!this.settings.helpers.length) return false
        let ret = false
        this.settings.helpers.some ((helper,index) => {
            if( helper.sipcall && (helper.sipcall.isbizzy != true)) {
                ret = index
                return true
            }
            return false
        })
        return ret
    }

    acceptIncomingCall(plugin, jsep) {
        plugin.isbizzy = true
        plugin.createAnswer({
            jsep: jsep, 
            tracks: [{type: 'audio', capture: true, recv: true}], 
            success: function (jsep) {
                plugin.send({message: {request: "accept"}, jsep: jsep})
            }, error: function (error) {
                console.error(error)
            }
        });
    }

    async makeCall(phoneNumber) {
        const helper = await this.getFreeHelper()
        const plugin = helper.sipcall
        plugin.isbizzy = true
        const tracks = [{type: 'audio', capture: true, recv: true}]
        const {ip} = this.settings.telSettings
        const uri = `sip:${phoneNumber}@${ip}`
        
        // company_id, 
        // agent_id 
        // responsible_user_id?

        plugin.createOffer({
            tracks: tracks, 
            success: function (jsep) {
                plugin.send({message: {request: "call", uri}, jsep: jsep})
            }, error: function (error) {
                console.error(error)
            }
        });
        
        return {
            plugin_id: plugin.id,
            phoneNumber
        }
    }

    async makeTransfe(item) {
        const plugin =  item.plugin
        plugin.isbizzy = true
        const {ip, port} = this.settings.telSettings
        const uri =  'sip:'+item.number+'@'+ip+':'+port
        const msg = {
            request: "transfer",
            uri,
            replace: plugin.callId,
            test: 'this is addition array test'
        }
        plugin.send({message: msg});
        console.log('makeTransfe', msg);
    }

    async makeDtmfTransfer(item) {
        
        this.makeDtmf(item.plugin, item.number)
    }

    async makeDtmf(plugin, dtmfString) {
        plugin.dtmf({
            dtmf: {tones: ''+dtmfString},
            success: (message) => {
                console.log('Успешное makeDtmf:', {plugin}, dtmfString);
            },
            error: (error) => {
                console.error('Ошибка makeDtmf:', error);
            }
        });
    }

    async makehangup(_plugin) {
        _plugin.send({
            message: { request: "decline"},
            success: () => {
                console.log('сброс вызова');
            },
            error: (error) => {
                console.error('Ошибка при завершении вызова:', error);
            }
        });
        _plugin.hangup();
    }

    get ipPort() {
        return {
            ip: this.settings.telSettings.ip,
            port: this.settings.telSettings.port
        }
    }
    
}