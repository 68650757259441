<template>
    <div class="topbar-content">
        <div class="topbar-content__top">
            <slot name="header"></slot>
            <div class="topbar-content__tools">
                <button class="button sideBar__button button_with_icon button_type_icon" @click="hendleCloseClick">
                    <svg aria-hidden="true" width="20" height="20">
                        <use href="#icon-close"></use>
                    </svg>
                </button>
            </div>
        </div>
        <div class="topbar-content__body">
            <slot name="body"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            hendleCloseClick() {
                this.close()
            },
            close() {
                this.$emit('hide',false)
            }
        }
    }
</script>

<style lang="scss">
    .topbar-content{
        display: flex;
        flex-direction: column;
        max-height: 500px;
        //overflow: auto;
        &__top {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .title { 
                color: #0A0A17;
                font-family: Inter;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 32px; /* 133.333% */
            }
        }
        &__tools {
            margin-left: auto;
        }

        &__row {
            display: flex;
            align-items: flex-start;
            margin-left: -12px;
            margin-right: -12px;
            margin-bottom: 16px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &__col {
            flex: 1 1 auto;
            padding-left: 12px;
            padding-right: 12px;
        }
    }
</style>