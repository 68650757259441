import { eventBus } from '@eventBus'
export default {
    data() {
        return {
            timer: false,
            channel: null,
            isJanus: false,
            tabs: [],
            currentTab: null
        }
    },
    mounted() {
        
        eventBus.$on('globalMakeCall', this.tabMakeCall);
        eventBus.$on('companyisChanget', this.tabChangeCompanyEmit);

        
        this.channel = new BroadcastChannel('tabchanel');
        const tab = this.createTab()
        this.currentTab = tab
        this.channel.postMessage({ type: 'addNewTab',tab });

        this.timer = setTimeout(() => {
          this.tabInitJanus()
        }, 500)


        
        this.channel.onmessage = (event) => {
            const data = event.data;
           console.log('tabs:',data);
            switch (data.type) {
                case 'addNewTab':
                    this.handleAddNewTab(data);
                    break;
                case 'checkTabs':
                    this.checkTabsResponse();
                    break;
                case 'responceTab':
                    this.handleResponceOtherTabs(data)
                    break
                case 'tabClose':
                    this.handleTabClose(data);
                    break
                case 'startJanus':
                    this.otherTabStartJanus(data)
                    break
                case 'tabMakeCall':
                    this.tabMakeCall(data)
                    break
                case 'changeCompany':
                    this.tabChangeCompany(data)
                    break
            }
        };
        const _this = this
        window.addEventListener('unload', function (event) {
            _this.channel.postMessage({ type: 'tabClose', tab: _this.currentTab });
        });
    },
    methods: {
        tabInitJanus() {
            console.log('tabs:','запускаем janus');
            this.channel.postMessage({ type: 'startJanus', tab: this.currentTab });
            this.currentTab.isJanus = true
            this.initTel()
        },
        handleAddNewTab(data) {
            console.log('tabs:','Новая вкладка', data.tab);
            this.saveTab(data.tab)
            this.channel.postMessage({ type: 'responceTab', tab: this.currentTab });
        },
        handleTabClose(data) {
            console.log('tabs:','закрытая владка', data.tab);
            this.removeFromTabsd(data.tab)
            const tabs = this.tabs
            if(data.tab.isJanus && (!tabs.length ||  tabs[0].id > this.currentTab.id)) {
                this.tabInitJanus()
            }
        },
        handleResponceOtherTabs(data) {
            if(this.timer) clearTimeout(this.timer)
            this.saveTab(data.tab)
        },
        createTab() {
            return {
                id: new Date().getTime(),
                isJanus: null,
            }
        },
        removeFromTabsd(tab) {
            const index = this.tabs.findIndex(_tab => _tab.id === tab.id);
            if (index > -1) {
                this.tabs.splice(index, 1);
            }
            console.log('tabs:',this.tabs);
        },
        saveTab(newtab) {
            const exist = this.tabs.some(tab => tab.id === newtab.id);
            if (!exist) {
                this.tabs.push(newtab);
            }
            console.log('tabs:',this.tabs);
        },
        otherTabStartJanus(data) {
            this.tabsChangeJanusStatus(data.tab)
        },
        tabsChangeJanusStatus(tab) {
            const tabFromList = this.tabs.find(_tab=>_tab.id === tab.id)
            tabFromList.isJanus = tabFromList.isJanus
            console.log(this.tabs,tabFromList);
        },

        tabChangeCompanyEmit(data)  {
            this.channel.postMessage({ type: 'changeCompany', tab: this.currentTab });  
        },

        tabChangeCompany() {
            this.$store.dispatch('AUTH_REFRESH')
            eventBus.$emit('softRefresh')
        },


        tabMakeCall(data) {
            if(this.currentTab.isJanus) {
                this.handleTabCalling(data);
                return
            } else if(!data.tab || this.currentTab.id == data.tab.id) {
                this.channel.postMessage({ type: 'tabMakeCall', phoneNumber:data.phoneNumber, dump_id: data.dump_id, tab: this.currentTab });
                return
            }
        },


        PostNewTab() {
            this.channel.postMessage({ type: 'checkTabsResponse', id: this.currentTab.tab_id });
        },
        checkTabsResponse() {
            this.channel.postMessage({ type: 'responceTab', tab: this.currentTab });
        }
    }
}
