import api from '../../api'
export default {
    state: {
        user: null,
        menu: null,
        perm: null,
    },
    mutations: {
        porfile_success(state,data) {
            state.user = data
            state.perm = data.permissions
        },
        RESET_PROFILE(state) {
            state.user = null
            state.perm = null
            state.menu = null
        }
    },
    actions: {
        GET_PROFILE({commit, state}) {
            return new Promise( (resolve, reject) => {
                if(state.user && state.perm){
                    resolve()
                    return 
                }
                api.profile.get().then(res=>{
                    commit('porfile_success', res)
                    resolve(res)
                }).catch(err=>{
                    reject(err)
                })
            })
        },
        RFRESH_PROFILE({commit, state}) {
            
            return new Promise( (resolve, reject) => {
                api.profile.get().then(res=>{
                    commit('porfile_success', res)
                    resolve(res)
                }).catch(err=>{
                    reject(err)
                })
            })
        },

        RESET_PROFILE({commit, state}) {
            commit('RESET_PROFILE', null)
        }
    },
    getters: {
        user: state => state.user,
        menu: state => state.menu,
        perm: state => state.perm
    }
}
  