<template>
    <div class="tel-wind__input">
        <!-- <pre v-if="value.plugin">
            {{ value.plugin.id }}
        </pre> -->
        <v-custom-input class="size_medium width__full"
            placeholder="введите номер"
            v-model="phoneNumber"
            @keyup="handlKeyUp"
        />
        
        <button class="tel-wind__call button button_with_icon button_type_icon button_color_transp-black" 
            type="submit"
            :disabled="validation"
            :style='{opacity: validation ? .5 : "" }'
                @click="$emit('action',{action:'call', phoneNumber: phoneNumber})"
            >
                <svg aria-hidden="true" width="30" height="30">   
                    <use href="#icon-phone-circle"></use> 
                </svg>
        </button>
    </div>
</template>

<script>
    import vCustomInput from '@components/global/CustomInput'
    export default {
        props: {
            isValidate: {
                type: Boolean,
                default: false
            },
            validate: {
                type: String,
                default: ''
            }
        },
        components: {
            vCustomInput
        },
        computed: {
            validation() {
                return !this.phoneNumber.length || (this.isValidate && this.validate == this.phoneNumber)
            }
        },
        data() {
            return {
                phoneNumber: '',
            }
        },
        methods: {
            handlKeyUp() {
                this.phoneNumber = this.phoneNumber.replace(/[^+\d]|(?!^)\+/g, '')
            },
        }
    }
</script>

<style lang="scss">

</style>