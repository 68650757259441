import req from './request'

const DEFAULT_ENDPOINTS = {
    telephonys: '/communications/telephony'
}

export default {
    telephonys(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.telephonys, param)
    },
}