export default {
    data() {
        const {
            RESET_PROFILE, RESET_AUTH,RESET_CAR_REFRENCES,RESET_CHATS,RESET_DRIVER_REFRENCES,RESET_PERMISSION
        } = this.$store._actions;
        return {
            RESET_CAR_REFRENCES: RESET_CAR_REFRENCES[0],
            RESET_PROFILE: RESET_PROFILE[0],
            RESET_AUTH: RESET_AUTH[0],
            RESET_CHATS: RESET_CHATS[0],
            RESET_DRIVER_REFRENCES: RESET_DRIVER_REFRENCES[0],
            RESET_PERMISSION: RESET_PERMISSION[0],
        }
    },
    methods: {
        storeReset() {
            this.RESET_AUTH();
            this.RESET_PROFILE();
            this.RESET_CAR_REFRENCES();
            this.RESET_CHATS();
            this.RESET_DRIVER_REFRENCES();
            this.RESET_PERMISSION();
        }
    }
}