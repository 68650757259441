<template>
    <div class="chatr-footer" v-if="value">
        <div class="chatr-footer__item">
            всего <span class="chatr-footer__cercle">{{value.all_chat}}</span>
        </div>
        <div class="chatr-footer__item">
            Новые <span class="chatr-footer__cercle chatr-footer__cercle_color_green">
                {{ value.new_chat }}
            </span>
        </div>
        <div class="chatr-footer__item">
            Завершенные <span class="chatr-footer__cercle chatr-footer__cercle_color_grey">
                {{ value.closed_chat }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        value: ''
    },
    data () {
        return {}
    }
}
</script>

<style lang="scss">
    .chatr-footer {
        display: flex;
        &__item {
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;
            color: #0A0A17;
        }
        &__cercle {
            margin-left: 8px;
            flex: 0 0 24px;
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #3A4146;
            color: #fff;
            border-radius: 100%;

            &_color {
                &_green {
                    background-color: #30C66C;
                }

                &_grey {
                    background-color: #919197;
                }
            }
        }
    }
</style>