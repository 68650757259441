<template>
    <div class="message-area" :class="{
        'message-area_type_focus' : isFocus,
        'message-area_type_disabled': disabled
    }">
        <div class="message-area__input">
            <textarea 
                ref="textarea"
                :name="name"
                :value="value" 
                :placeholder="placeholder"
                :disabled="disabled"
                class="message-area__textarea" 
                @focus="(e)=>{ this.isFocus = true; this.$emit('focus', e) }"
                @blur="(e)=>{ this.isFocus = false; this.$emit('blur', e) }"
                @input="handlInput($event)" 
                @click="handlMouseClick"
                @keyup="handlKeyUp"
                v-on:keypress.ctrl.10.13="handlCtrlEnter()"
            >
            </textarea>
            <v-button  ref="button" type="button" class="message-area__button button_type_static button_type_icon"  v-if="buttonIcon || isload"
                :loading="isload"
                :disabled="disabled || isload"
                :class="buttonIconClass"
                @click="handlClick($event)"
            >
                <svg aria-hidden="true" v-if="buttonIcon" :width="iconSize.w" :height="iconSize.h">
                    <use :href="buttonIcon"></use>
                </svg>
            </v-button>
        </div>

    </div>
</template>

<script>
import vButton from './vButton.vue';
    export default {
        components: {
            vButton
        },
        props: {
            isload: {
                type:Boolean,
                default:false
            },
            placeholder: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            value: [String, Number],
            disabled: {
                type: Boolean,
                default: false
            },
            iconSize: {
                type: Object,
                default: function() {
                    return { w: 20, h: 20 };
                }
            },
            buttonIcon: {
                type: String,
                default: ''
            },
            buttonIconClass: { 
                type: Object,
                default: () => {}
            },
        },
        watch: {
            value() {
                this.$nextTick(() => {
                    this.fisTextAreaHeight()
                })
            }
        },
        data() {
            return {
                isFocus: false,
                selectionStart: false,
            }
        },
        mounted() {
            this.fisTextAreaHeight()
        },
        methods: {
            handlInput(event) {
                this.$emit('input', event.target.value)    
            },
            handlMouseClick($event) {
                this.$emit('mouseClick', $event)
            },
            handlKeyUp($event) {
                this.$emit('keyup', $event)
            },

            fisTextAreaHeight() {
                const textarea = this.$refs.textarea
                textarea.style.height = '20px'
                textarea.style.height = textarea.scrollHeight+'px'
            },
            handlClick() {
                const textarea = this.$refs.textarea
                this.$emit('click', textarea.value)
                textarea.value = ''
            },
            handlCtrlEnter() {
                this.handlClick()
            }
        }
    }
</script>

<style lang="scss">
@import '../../../../gulp-src/app/scss/_variables';
@import '../../../../gulp-src/app/scss/_mixins';

    .message-area {
        border: 1px solid #CBCCCF;
        border-radius: 6px;
        padding: 5px 16px;
        padding-right: 6px;
        &__input {
            position: relative;
            min-height: 48px;
        }
        &__textarea {
            flex: 1 1 auto;
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            margin-top: auto;
            margin-bottom: auto;
            resize: none;
            min-height: 20px;
            height: 20px;
            max-height: 500px;
            @include scrollbar($thumb-color:#3A4146, $track-color:#CBCCCF);
            padding-right: 38px;
        }

        & &__button {
            flex: 0 0 auto;
            margin-left: 16px;
            margin-top: auto;
            position: absolute;
            bottom: 0;
            right: 16px;
        }

        &__input {
            display: flex;
            flex-direction: flex-end;
        }
        &_type {
            &_focus {
                border-color:#3A4146;
            }

            &_disabled {
                background-color: #f5f5f5;
            }
        }
    }
</style>