<template>
      <div class="header-user_status" @click="isBuzzy?false:listExpand=!listExpand" 
    :class="{'is-bizzy':isBuzzy}"
      v-click-outside="()=>{listExpand=false}">
      
        <v-status :text="user.online_status.text" :type="user.online_status.color" />
        <v-list class="dropList_type_pull-top"
            v-if="operator_online_status"
            :selectedId="user.online_status.id"
            :list="operator_online_status" 
            type='status' 
            :expand="listExpand"
            @click="handleClick($event)"
        />
    </div>
</template>

<script>
import api from '../../../api'
import vStatus from './../../global/Status'
import vList from './../../global/DropDownList'
import { mapState } from 'vuex'

export default {
    props:{
        permission:{
            type: Boolean,
            default: false 
        }
    },
    components: {
        vStatus,
        vList
    },
    computed: {
        ...mapState({
            user: state => state.profile.user, 
            operator_online_status: state => state.references.operator_online_status
        })
    },
    data() {
        return {
            listExpand: false,
            isBuzzy:false
        }
    },


    methods: {
        handleClick(item) {
            if(this.isBuzzy) return
            this.isBuzzy = true
            api.profile.status({status:item.id}).then(res=>{
                if(res.status) {
                    this.$emit('update', res.response)
                } else {
                    this.$toast.error(err.message || err, {
                        timeout: 5000
                    });
                }
            }).catch(err=>{
                this.$toast.error(err.message || err, {
                    timeout: 5000
                });
            }).finally(()=>{
                this.isBuzzy = false
            })
        }
    },
}
</script>

<style lang="scss">
.header-user_status{
    position: relative;
    transition: .3s;
    &.is-bizzy {
        opacity: .3;
    }
}
</style>