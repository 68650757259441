import req from './request'

const DEFAULT_ENDPOINTS = {
    banner: '/promotions/banner',
    reference: '/promotions/banner/reference',
    baigaSetting: '/promotions/baiga/setting',
    baigaReferences: '/promotions/baiga/setting/reference',
    baigaReport: '/promotions/baiga/report',
    baigaReportReference: '/promotions/baiga/report/reference',
    baigaPublish: '/promotions/baiga/publish',
    promotions: '/promotions/base',
    config: '/promotions/base/list-config',
    tabs: '/promotions/base/tabs',
    referenceBase: '/promotions/base/reference',
    mechanic: '/promotions/mechanic',
    workRule: '/promotions/work-rule',
    workRuleSettingsEnterExit:'/promotions/work-rule/setting-enter-exit',
    raffle: '/promotions/raffle',
    raffleDrivers:'/promotions/raffle/drivers',
    promo: '/promo-code/set',
    promoRef: '/promo-code/set/reference',
    promoDetails: '/promo-code',
    polls: '/polls/default',
    pollsQuestions: '/polls/default/questions',
    pollsAnswers: '/polls/default/answers-statistic',
    pollsExport: '/polls/default/export',
    transactions: '/promotions/reports/transactions',
    driversByTransactions: '/promotions/reports/drivers-by-transactions',
    drivers: '/promotions/drivers',
    driversParticipateHistory: '/promotions/drivers/participate-history',
    driversPayHistory: '/promotions/drivers/pay-history'
}

export default {
    promoDetails(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promoDetails, param)
    },
    promo(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promo, param)
    },
    promoRef(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promoRef, param)
    },
    raffle(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.raffle, param)
    },
    workRuleSettingsEnterExit(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.workRuleSettingsEnterExit, param)
    },
    workRule(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.workRule, param)
    },
    mechanic(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.mechanic, param)
    },
    referenceBase(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.referenceBase, param)
    },
    tabs(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.tabs, param)
    },
    list(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.promotions, param)
    },
    config(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.config, param)
    },
    banner(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.banner, param)
    },
    reference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    },
    baigaSetting(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.baigaSetting, param)
    },
    baigaReferences(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.baigaReferences, param)
    },
    baigaReport(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.baigaReport, param)
    },
    baigaReportReference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.baigaReportReference, param)
    },
    baigaPublish(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.baigaPublish, param)
    },
    raffleDrivers(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.raffleDrivers, param)
    },
    polls(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.polls, param)
    },
    pollsQuestions(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.pollsQuestions, param)
    },
    pollsAnswers(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.pollsAnswers, param)
    },
    pollsExport(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.pollsExport, param)
    },
    transactions(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.transactions, param)
    },
    driversByTransactions(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversByTransactions, param)
    },
    drivers(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.drivers, param)
    },
    driversParticipateHistory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversParticipateHistory, param)
    },
    driversPayHistory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversPayHistory, param)
    },
    
    
}