<template>
    <div class="navbar-nav">
        <ul class="navbar-nav__list">
            <li class="navbar-nav__item" v-for="(item,index) in menu" :key="index" @click.stop>
                <v-link
                    :search="search"
                    v-if="item.href"
                    :item="item"
                    :expand='expand'
                    @routeClick="$emit('routeClick')"
                />
                <v-submenu
                    v-else
                    :search="search"
                    :item="item"
                    :expand='expand'
                    @click="$emit('submenuClick')"
                    @routeClick="$emit('routeClick')"
                />
            </li>
        </ul>
    </div>
</template>

<script>
import vLink from './NavLink'
import vSubmenu from './NavSubMenu'
export default {
    name:'NavMenu',
    props: {
        search: {
            type: String,
            default: ''
        },
        expand: {
            type: Boolean,
            default: false
        },
        menu: {
            type: Array,
            default: () => []
        }
    },
    components: {
        vLink,
        vSubmenu
    },
   
}
</script>

<style lang="scss">

@import '../../../../../gulp-src/app/scss/mixins';
.navbar-nav {
    overflow: auto;
    padding-right: 10px;
    margin-right: -10px;
    @include scrollbar();

    &__item {
        .expand-enter-active, .expand-leave-active {
            transition: .5s;
        }
        .expand-enter, .expand-leave-to {
            max-width: 0;
            opacity: 0;
        }
    }
}
</style>