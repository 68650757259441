export default {
    data() {
        return {
            errorClasss:'validation_type_error',
            errorLabel:'label',
            errors: {},
            isDictionary: false
        }
    },  
    methods: {
        pushErrors(errors){
            this.errors = [];
            errors.forEach(error => {
                const name = error.field
                const $item = this.$el.querySelector(`[name=${name}]`)
                $item.parentElement.classList.add(this.errorClasss)
                this[name+'Error'] = error.message
                this.errors.push(name+'Error')
            });
        },
        removeErrors(){
            
            this.$el.querySelectorAll('.'+this.errorClasss).forEach(item=>{
                item.classList.remove(this.errorClasss)
            })
            if(!this.errors.length) return
            this.errors.forEach(error=>{
                this[error] = false;
            })
        },
        fillErrors(error) {
            if(!error.length) return
            // this.errors = {} 
            error.forEach(err=>{
                if(!error.length) return
                if(this.isDictionary) {
                    for (const key in this.errorDictionary) {
                        if (Object.hasOwnProperty.call(this.errorDictionary, key)) {
                            const element = this.errorDictionary[key];
                            if(err.field == key) err.field = element
                        }
                    }
                }

                if(this.isToastedFields) {
                    this.$toastErrors.forEach(field => {
                        if(err.field == field) this.$toast.error(err.message) 
                    })
                }
                // this.errors[err.field] = err.message
                this.$set(this.errors, err.field, err.message)
            })
        },
        clearError(errors) {
            if(!errors) {
                this.errors = []
                return
            }
            errors.forEach(error=>{
                delete this.errors[error]
            })
        },
        clearErrors() {
            this.clearError()
        }
    },
}
