import req from './request'

const DEFAULT_ENDPOINTS = {
    car: '/rent/car',
    reference: '/rent/reference',
    request: '/rent/request',
    allCar: '/rent/all-car'
}

export default {
    car(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.car, param)
    },
    reference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    },
    request(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.request, param)
    },
    allCar(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.allCar, param)
    },

}