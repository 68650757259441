import req from './request'

const DEFAULT_ENDPOINTS = {
    teams: '/teams',
    drivers: '/teams/driver',
    orderReport: '/teams/calculate-history',
    payoutHistory: '/teams/payout-history',
    workRule: '/teams/work-rule',
    calculateDetailHistory: '/teams/calculate-detail-history',
    teamsCandidates: '/teams/candidates',
    teamsTop: '/teams/top',
    payRequest: '/teams/pay-request',
    setting: '/teams/setting',
    verify: '/team/verify'
}

export default {
    workRule(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.workRule, param)
    },
    teams(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.teams, param)
    },
    drivers(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.drivers, param)
    },
    orderReport(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.orderReport, param)
    },
    payoutHistory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.payoutHistory, param)
    },
    calculateDetailHistory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.calculateDetailHistory, param)
    },
    teamsCandidates(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.teamsCandidates, param)
    },
    teamsTop(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.teamsTop, param)
    },
    payRequest(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.payRequest, param)
    },
    setting(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.setting, param)
    },
    verify(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.verify, param)
    }
}