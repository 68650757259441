
<template>
   <button class="button" :disabled="disabled || loading"
        @click="$emit('click',$event)"
        :class="{
            ['button_size_'+size]: !!size,
            'button_size_large' : !size,
            'button_type_disabled': disabled,
            'button_type_loading': loading
        }"
    >
        <span><slot></slot></span>

        <transition name="button_preloader">
            <v-preloader v-if="loading" class="preloader_type_button" size="ex-small"/>
        </transition>

    </button>

</template>

<script>
import vPreloader from './Preloader.vue';
export default {
    name: "vButton",
    components: {
        vPreloader
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: ''
        }
    },
    
}
</script>