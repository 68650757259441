import req from './request'

const DEFAULT_ENDPOINTS = {
    permissions: '/permissions',
}

export default {
    get (payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.permissions, param)
    },
}
