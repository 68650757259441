export default class cLog {
    constructor(options) {
        this.cache(options)
    }
                
    cache(options) {
        this.settings = {
            ...this.defaults,
            ...options
        }
    }
                
    get defaults() {
        return {
            name: '',
            success: 'background: #0f0; color: #000; font-weight: 900; padding:5px',
            error: 'background: #f00; color: #000; font-weight: 900; padding:5px',
            info: 'background: cyan; color: #000; font-weight: 900; padding:5px'
        }
    }
    
                
    log(msg, objects = {}) {
        const color = this.settings.info
        const message = `%c${this.settings.name}: ${msg}`;
        console.log(message, color, objects);
    }

    error(msg, objects = {}) {
        const color = this.settings.error
        const message = `%c${this.settings.name}: ${msg}`;
        console.log(message, color, objects);
    }

    success(msg, objects = {}) {
        const color = this.settings.success
        const message = `%c${this.settings.name}: ${msg}`;
        console.log(message, color, objects);
    }

    
}