

import req from './request'

const DEFAULT_ENDPOINTS = {
    create: '/referrals/default/create', 
}

export default {
    create(payload, param, url) {
        return req[method || 'get'](payload, url || DEFAULT_ENDPOINTS.create, param)
    }, 
    
}