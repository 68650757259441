<template>
    <div class="chat-toggle" v-if="isready">
        <button :disabled="disabled" @click="$emit('click','all')" class="button button_size_small" :title="value.new" :class="{
            'button_color_black': selected == 'all',
        }" >
            Все чаты
            <span class="chat-toggle__counter" v-if="mes_new > 0 ">
                <template v-if="mes_new <= 99">{{mes_new}}</template>
                <template v-else> 99+ </template>
            </span>
        </button>
        <button :disabled="disabled" @click="$emit('click','my')" class="button button_size_small" :title="mes_my" :class="{
            'button_color_black': selected == 'my',
        }">
            Мои чаты
            <span class="chat-toggle__counter" v-if="mes_my > 0">
                <template v-if="mes_my <= 99">{{mes_my}}</template>
                <template v-else> 99+ </template>
            </span>
        </button>
        <button :disabled="disabled" @click="$emit('click','history')" class="button button_size_small " :class="{
            'button_color_black': selected == 'history',
        }">
            История
        </button>
    </div>
</template>

<script>
import { eventBus } from '@eventBus'
export default {
    props: {
        value: false,
        selected: {
            type: String,
            default: ''
        },
        disabled : {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isready: false,
            mes_my: 0, 
            mes_new: 0,
        }
    },
    mounted() {
        this.mes_my = this.value.total_unread_my_messages
        this.mes_new = this.value.total_unread_common_chats_messages
        eventBus.$on('socketUpdateCounter', this.handleUpdateCounter);
        this.isready = true

    },
    methods: {
        handleUpdateCounter(messageData) {
            if(messageData) {
                this.mes_my = messageData.total_unread_my_message
                this.mes_new = messageData.total_unread_message
            }
        }
    }
}
</script>

<style lang="scss">
.chat-toggle {
    display: flex;
    padding: 4px;
    border: 1px solid #CBCCCF;
    border-radius: 6px;
    margin: 16px 0;
    overflow: hidden;

    button {
        margin-left: 0;
        margin-right: 0;
        flex: 1 1 auto;
        white-space: nowrap;
        &:hover span{
            color: #3A4146!important;    
        }
    }

    &__counter {
        flex: 0 0 24px;
        width: 24px;
        height: 24px;
        border-radius: 100%;
        display: inline-block;
        background-color: #fff;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #3A4146;
        justify-content: center;
        margin-left: 8px;
    }
}

</style>