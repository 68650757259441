import api from '../../api/auth'
export default {
    state: {
        status: '',
        token: localStorage.getItem('token') || '',
        sms_token: null,
        common: {},
    },
    mutations: {
        auth_request(state){
            state.status = 'loading'
        },
        auth_success(state, common) {
            state.status = 'success'
            state.token = common.token || common.Authorization
            state.common = common.common
            const sms_token = common.common['sms-token']
            state.sms_token = sms_token
        },
        auth_error(state){
            state.status = 'error'
        },
        logout(state){
            state.status = ''
            state.token = ''
            state.common = ''
            state.sms_token = null
            window.axios.defaults.headers.common = {}
        },
      },
    actions: {
        login({ commit, dispatch }, data){
            const { email, password } = data
            const payload = {email, password }
            return new Promise((resolve, reject) => {
                commit('auth_request')
                api.auth.login(payload).then(res=>{
                    const time  = new Date().getTime()
                    const _res =  res.response
                    const token = _res.token
                    const common = {
                        'Authorization': token,
                        'session-id': _res.session_id,
                        'session-identity': _res.session_identity,
                        'unique-id': _res.unique_id,
                        'sms-token': _res.sms_token
                    }
                    dispatch('updateHeader', common)
                    resolve(res)
                }).catch(err=>{
                    commit('auth_error')
                    localStorage.removeItem('common')
                    reject(err)
                })
            })
        },
        updateHeader({commit, dispatch}, common) {
            dispatch('saveLocalStorage', common)
            dispatch('setWAheaders', common)
            dispatch('auth_success', common)
        },
        saveLocalStorage({commit, dispatch}, common) {
            localStorage.removeItem('common')
            localStorage.setItem('common', JSON.stringify(common))
        },
        // setLogDate({commit, dispatch}, time) {
        //     localStorage.setItem('logintime', time)
        // },

        setWAheaders({commit, dispatch}, common) {
            window.axios.defaults.headers.common = '';
            window.axios.defaults.headers.common = common;
        },

        auth_success({commit}, common) {
            commit('auth_success', {common})
        },

        RESET_AUTH: ({commit, state},) => {
            commit('logout')
            localStorage.removeItem('common')
            window.axios.defaults.headers.common = {}
        },
        AUTH_REFRESH({commit}) {
            const common = JSON.parse(localStorage.getItem('common')) ||  window.axios.defaults.headers.common
            if(common) {
                window.axios.defaults.headers.common = common
                commit('auth_success', {token:common.Authorization,common})
            }
        }
    },
    getters: {
        isLoggedIn: state => !!state.token,
        authStatus: state => state.status,
        authCommon: state => state.common,
        is_sms_confirm: state => state.sms_token
    }
}
