<template>
    <v-side-swipe-bar :show="$store.getters.barSearch.value" @hide="$emit('hide')" :outsideDisabed="true">
         <div class="qpay__header 3" slot="header">
            <p> Поиск</p>
        </div>
        <div slot="body">
            <div class="form__row">
                <v-driver-search
                    class="size_large"
                    icon="#icon-search"
                    placeholder="Введите"
                    label="Поиск водителя"
                    buttonIcon="icon-loading"
                    :buttonIconClass="{'await':true}"
                    @listupdate="HandlerListUpdatet($event)"
                    :showList="false"
                />
            </div>
            <template v-if="list">
                <div class="form__row" v-for="item in list" :key="item.id">
                    <button 
                        @click="HandlerClick(item)"
                        class="loop_btn button button_text_left button_color_black button_with_icon"
                    >
                        <svg width="20" height="20"> <use href="#icon-user"></use> </svg>
                        <span>
                            {{item.text.split('-')[0]}}
                        </span>
                        <span>
                            {{item.text.split('-')[1]}} <br> {{item.text.split('-')[2]}}
                        </span>
                    </button>
                </div>
            </template>
        </div>
    </v-side-swipe-bar>
</template>

<script>
import vDriverSearch from './DriverSearch.vue'
import vSideSwipeBar from '../../global/SideSwipeBar'
export default {
       components: {
        vSideSwipeBar,
        vDriverSearch
    },
    data() {
        return {
            list:false
        }
    },
    methods: {
        HandlerListUpdatet(list) {
            this.list = list
        },
        HandlerClick(item){
            const route = {name: "DriverDetail", query: { driver_id: item.id, park_id: item.full_name.park_id }}
            const routeData = this.$router.resolve(route);
            window.open(routeData.href, '_blank');
        }
    },
}
</script>

<style lang="scss">
.loop_btn {
    margin-right: 0;
    width: 100%;
    height: auto;
    min-height: 48px;
    span {
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
    }
}
</style>