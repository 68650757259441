<template>
    <div class="fast-phrase"
        ref="draggableContainer"
        :class="{'fast-phrase__draggable': !this.$store.getters.barChat.isPin}"
    >
        
        <div class="draggable__border draggable__border-left" @mousedown.stop="handleResizeLeft($event)"></div>
        <div class="fast-phrase__cont">
            <template>
                <div class="fast-phrase__list" v-if="list.length">
                    <div class="fast-phrase__item" v-for="item in list" :key="item.id">
                        <v-button :size="'full-width'" class="fast-phrase__button" @click="handleItemClick(item)">
                            {{ item.name }}
                        </v-button>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import mixinDraggable from '@components/global/draggable.js'
import vButton from '../../global/vButton.vue';
import { mapState } from 'vuex'

export default {
    computed:{
        ...mapState({
            fastList: state => state.chat.fastphrase,
            barChat: state => state.chat,
        }),
    },
    mixins: [mixinDraggable],
    components: {
        vButton,
    },
    props: {
        list: {
            type: Array,
            default: () => []
        }
    }, 
    methods: { 
        handleItemClick(item){
            this.$emit('click', item)
        },
    }
}
</script>



<style lang="scss">
@import '../../../../../gulp-src/app/scss/_variables';
@import '../../../../../gulp-src/app/scss/_mixins';

    .fast-phrase{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 100%;
        width: 420px;
        background-color: #fff;
        box-shadow: -4px 0px 12px 0px rgba(58, 65, 70, 0.06), -20px 10px 16px 0px rgba(58, 65, 70, 0.12);
        left: initial!important;

        &__cont { 
            padding: 32px 24px;
            height: 100%;
        }
        &.is-loading {
            display: flex;
            justify-content: center;
            align-items: center;    
        }
        &__list {
            height: 100%;
            @include scrollbar($thumb-color:$global-dark-3a, $track-color:$global-gray);
            padding-right: 10px;
            margin-right: -10px;
        }
        &__draggable {
            border: 1px solid #CBCCCF;
            box-shadow: 0px 4px 8px rgba(58, 65, 70, 0.06), 0px 8px 24px rgba(58, 65, 70, 0.06);
            border-radius: 16px;
            
        }
        & &__button {

            color: $global-dark-3a;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            border-radius: 6px;
            border: 1px solid $global-gray;
            padding: 16px;
            margin-bottom: 20px;
            display: flex;
            justify-content: inherit;


            span {
                display:flex;
                width: 100%;
                white-space: unset;
                text-align: left;
            }
            // display: flex;
            // cursor: pointer;
            // &:last-child {
            //     margin-bottom: 0;
            // }
            &.active{
                background: #3a4146;
                color: #fff;
            }
        }
        .img-icon{
            margin-right: 12px;
            width: 20px;
            height: 20px;
        }
        .svg_arrow {
            margin-left: auto;
            margin-top: auto;
            margin-bottom: auto;
        }
        svg {
            fill: currentColor;
        }
    }
    //sideBar__wrapper
</style>