// Vue
import Vue from 'vue'
// Router
import router from './router'
// Vuex
import store from './store'
// Plugins
import './plugins'
// Components
import './components'
// Application
import App from './router/views/App'
import '../../gulp-src/app/index.scss'


// loadSprite('../../../static/design/sprite.svg')
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        const __store = store
        __store.dispatch('AUTH_REFRESH')
        if ( __store.getters.isLoggedIn && __store.getters.is_sms_confirm  !== true ) {
            next()
            return
        }
        next('/login') 
    } else {
        next() 
    }
})

router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = to.meta.title 
    });
});


new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

