import contactCollection from "./contactCollection";
export default class answeredCollection extends contactCollection {

    constructor(options) {
        super(options)
    }

    pushAudio(id,audio) {
        this.settings.collection.some((listItem)=> {
            if( listItem.plugin.id == id )  {
                listItem.audio = audio
                return true
            }
        })
        return false
    }
}