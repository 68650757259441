import { eventBus } from '@eventBus'
import cSocket from './cSocket'
export default {
    data() {
        return {
            cSocket: null
        }
    },
    methods: {
        initSocket() {
            this.cSocket = new cSocket({
                wss: 'wss://new-8000.fleetsoft.pro',
                token: this.user.token,
                name: '8000',
                onmessage: this.onmessage
            })
            eventBus.$on('socketEmit', this.cSocket.emit);
        },

        onmessage(action, data) {
            switch (action) {
                case 'update_counter':
                    eventBus.$emit('socketUpdateCounter', data);
                    break;
                case 'personal_message':
                    eventBus.$emit('socketMessage', data);
                    break;
                case 'new_chat':
                    eventBus.$emit('socketNewСhat', data)
                    break;
                case 'assign_end_message':
                    eventBus.$emit('socketAssignEndMessage', data);
                    break;
                case 'close':
                    console.log('socket chat: close', data)
                    break;
                case 'return_to_list':
                    //console.log('socket chat: return_to_list', data)
                    break;
                case 'assign_to_operator':
                    eventBus.$emit('socketRemoveChat', data)
                    break;
                default:
                    break;
            }
        },
    }
}