
import req from './request'

const DEFAULT_ENDPOINTS = {
    get: '/selfemployment',
    driversList: '/selfemployment/drivers-list',
    reference: '/selfemployment/reference',

}

export default {
    get(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.get, param)
    },
    driversList(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.driversList, param)
    },
    reference(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    }
}
