import Vue from 'vue';
import log from './customLog.js'
import api from '@api'

export default class Contact {
    constructor(options) {
        this.cache(options)
        this.init()

        return this
    }
                
    cache(options) {
        this.settings = Vue.observable({
            ...this.defaults,
            ...options
        });

        this.console = new log({name: '[contact]'})
    }
                
    get defaults() {
        return {
            plugin: null,
            janinfo: null,
            id: null,
            client: {
                number: null,
            },
            company: {
                name: null,
                number: null,
                settings: ''
            },
            onremvoed: false,
            transferring: false,
            audio: {
                isMute: false,
            },
            accepted: false,
            janus: null,
        }
    }
                
    init() {
        this.initId()
        const {result} = this.settings.janinfo.msg
        switch (result.event) {
            case 'incomingcall':
                this.initIncCallContact(result)
                break
            case 'calling':
                this.initCallContact(result)
                break
        }

        console.log(this.settings,{result});
    }

    initCallContact(result) {
        const number = this.getClientNumber(result)
        this.settings.client.number = number
    }
    initIncCallContact(result) {
        // if(!result.displayname) {
        //     result.displayname = `"{'company_id':'12','agent_id':'1024 101 102 1023 103 ','client':'+00000000000','type':'', 'transfer':'true', 'externall_call_id':'1724417211.509'}"`
        // }
        const data = this.parseData(result.displayname)
        // const {agent_id,
        //     client,
        //     company_id,
        //     responsible_user_id,
        //     transfer,
        //     type} = data

        this.settings.client.number = data.client
        this.getCompany(data.company_id).then((res) => {
            this.settings.company.settings = res
            this.settings.company.name = res.company_name
        })
    }

    parseData(data) {
        if(!data) return ''
        return JSON.parse(data.replaceAll('"','').replaceAll('\'','"'))
    }

    

    fillInfo() {
    }

    initId() {
        this.settings.id = this.settings.janinfo.msg.call_id || this.settings.janinfo.msg.result.call_id
    }

    async getCompany(id) {
        try {
            const res = await api.chat.telephonyTreatmentIncomingNumber({ company_id: id});

            // {
            //     "can_change_company": true,
            //     "profile_id": 1250,
            //     "user_id": 872,
            //     "company_id": 70,
            //     "company_name": "АЛГА"
            // }
            this.console.log('getCompany',res);
            
            return res.response;
        } catch (error) {
            this.console.error('getCompany', error, this.settings);
        }
    }

   

    

    //client
     
    getClientNumber(result) {
        const username = result.username
        if(!username) {
            this.console.error('no username', {janinfo: this.settings.janinfo})
            return
        }
        
        const pattern = /:(.*?)(?=@)/; // cut sip and ip
        const number = username.match(pattern)[1]
        return number
    }
    
    update(data) {
        this.plugin = data.plugin
        this.id = data.id
        this.janinfo = data.janinfo
    }
    setOnRemove() {
        this.settings.onremvoed = true
        this.settings.plugin = null
    }
    remove() {
        // this.plugin.remove()
    }

    muteAudio() {
        const plugin = this.settings.plugin
        this.settings.audio.isMute =  true
        const $audio = document.getElementById(`peervideom${plugin.id}`)
        $audio.volume = 0
        plugin.muteAudio(false)
    }

    unmuteAudio() {
        const plugin = this.settings.plugin
        this.settings.audio.isMute =  false
        const $audio = document.getElementById(`peervideom${plugin.id}`)
        $audio.volume = 1
        plugin.unmuteAudio(false)
    }

    
    get id() {
        return this.settings.id
    }

    set id(value) {
        this.settings.id = value
    }

    get number() {
        let ret = this.settings.client.number
        if(ret.indexOf("*") !== -1 ) {
            ret = this.settings.client.number.split('*')[1]
        }
        return ret
    }

    get name () {
        return this.settings.client.name
    }

    get plugin () {
        return this.settings.plugin
    }

    set plugin (value) {
        this.settings.plugin = value
    }

    get janinfo () {
        return this.settings.janinfo
    }

    set janinfo (value) {
        this.settings.janinfo = value
    }

    get transferring () {
        return this.settings.transferring
    }

    get company () {
        return this.settings.company 
    }

    get client () {
        return this.settings.client
    }

    get callId () {
        return this.settings.janinfo.msg.call_id
    }

    get audio () {
        return this.settings.audio
    }

    set audio (value) {
        this.settings.audio = value
    }

    get ipPort () {
        return this.settings.janus.ipPort
    }

    get accepted () {
        return this.settings.accepted
    }

    set accepted (value) {
        this.settings.accepted = value
    }

    get call_id () {
        return this.settings.janinfo.msg.call_id
    }    
}