import Vue from 'vue'
export default class callingCollection{

    constructor(options) {
        this.cache(options)
    }
    cache(options) {
        this.settings = Vue.observable({
            ...this.defaults,
            ...options
        });

    }
    get defaults() {
        return {
            collection: [],
        }
    }
    add (item) {
        this.settings.collection.push(item)
    }
    getById(id) {
        return this.settings.collection.find(item => item.plugin_id == id)
    }

    remove(id) {
        this.settings.collection = this.settings.collection.filter(item => item.plugin_id != id)
    }


    

}