var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.value)?_c('div',{staticClass:"tel-list__item",staticStyle:{"height":"auto"}},[_c('div',{staticClass:"tel-list__name"},[_vm._v(" "+_vm._s(_vm._f("formatTime")(_vm.timerTime))+" "),(_vm.value.audio)?_c('div',[(_vm.value.audio)?_c('audio',{ref:"audio",attrs:{"id":'peervideom' + _vm.value.plugin.id,"autoplay":""},on:{"canplaythrough":_vm.handleAudioReady}}):_vm._e()]):_vm._e(),(_vm.value.company)?[(_vm.value.company.settings.can_change_company)?_c('svg',{style:({marginLeft: '5px'}),attrs:{"width":"15","height":"15"}},[_c('use',{attrs:{"href":"#icon-link"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.value.company.name)+" ")]:_vm._e()],2),_c('div',{staticClass:"tel-list__number",class:{'tel-list__number-centered': _vm.value.client.name == 'неизвестный'}},[_vm._v(" "+_vm._s(_vm.value.number)+" "),_c('div',{staticClass:"tel-list__buttons"},[(_vm.value.audio)?[_c('button',{staticClass:"button button_with_icon button_type_icon",class:{
                        'button_color_transp-yellow': _vm.dtmf.isShow,
                        'button_color_transp-gray': !_vm.dtmf.isShow
                },style:({marginRight: '24px'}),on:{"click":function($event){_vm.dtmf.isShow = !_vm.dtmf.isShow}}},[_c('svg',{attrs:{"aria-hidden":"true","width":"9","height":"16"}},[_c('use',{attrs:{"href":"#icon-terminal"}})])]),_c('button',{staticClass:"button button_with_icon button_type_icon",class:{
                        'button_color_transp-yellow': _vm.showTransfer,
                        'button_color_transp-gray': !_vm.showTransfer
                },style:({marginRight: '24px'}),on:{"click":function($event){_vm.showTransfer = !_vm.showTransfer}}},[_c('svg',{attrs:{"aria-hidden":"true","width":"9","height":"16"}},[_c('use',{attrs:{"href":"#icon-call-transfer"}})])]),_c('button',{staticClass:"button button_with_icon button_type_icon",class:{
                        'button_color_transp-black': !this.value.audio.isMute,
                        'button_color_transp-gray': this.value.audio.isMute
                },style:({marginRight: '24px'}),on:{"click":_vm.handleMuted}},[_c('svg',{attrs:{"aria-hidden":"true","width":"9","height":"16"}},[_c('use',{attrs:{"href":"#icon-microphone"}})])])]:_vm._e(),_c('button',{staticClass:"tel-list__button-red button button_with_icon button_type_icon button_color_transp-black",on:{"click":_vm.handleHangup}},[_c('svg',{attrs:{"aria-hidden":"true","width":"30","height":"30"}},[_c('use',{attrs:{"href":"#icon-phone-denay-circle"}})])])],2)]),(_vm.showTransfer)?_c('div',{staticClass:"tel-list__number",style:({marginTop: '20px', alignItems: 'center'})},[_c('v-custom-input',{staticClass:"size_medium width__full",attrs:{"placeholder":"введите номер"},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('button',{staticClass:"tel-wind__call button button_with_icon button_type_icon button_color_transp-black",style:({opacity: _vm.validate ? .5 : "" }),attrs:{"type":"submit","disabled":_vm.validate},on:{"click":_vm.handleTransfer}},[_c('svg',{attrs:{"aria-hidden":"true","width":"30","height":"30"}},[_c('use',{attrs:{"href":"#icon-transfer-circle"}})])])],1):_vm._e(),(_vm.dtmf.isShow)?_c('div',{staticClass:"tel-list__number",style:({marginTop: '20px', alignItems: 'center'})},[_c('v-custom-input',{ref:"dtmfInput",staticClass:"size_medium width__full",attrs:{"placeholder":"введите dtmf"},on:{"input":_vm.handlInputDTMF,"keyup":_vm.handlKeyUpDTMF},model:{value:(_vm.dtmf.value),callback:function ($$v) {_vm.$set(_vm.dtmf, "value", $$v)},expression:"dtmf.value"}})],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }