import req from './request'

const DEFAULT_ENDPOINTS = {
    create: '/driver',
    restricted: '/drivers/default/restricted-type',
    list: '/candidate',
    config: '/candidate/list-config',
    reference: '/candidate/reference',
    // searchByPhone:'/drivers/base/search-by-phone',
    searchByPhone: '/drivers/base/verify-profile',
    workRule: '/work-rule',

    assetCall: '/candidate/asset-call',
    possiblePlatform: '/candidate/possible-platform',
    detail: '/candidate/list/detail',
    subStatus: '/candidate/sub-status/'
}

export default {
    create(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.create, param)
    },
    restricted(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.restricted, param)
    },
    list(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.list, param)
    },
    config(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.config, param)
    }, 
    reference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    }, 
    searchByPhone(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.searchByPhone, param)
    },
    workRule(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.workRule, param)
    },
    assetCall(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.assetCall, param)
    },
    possiblePlatform(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.possiblePlatform, param)
    },
    detail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.detail, param)
    },
    subStatus(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.subStatus, param)
    }
}