<template>
    <div class="tel" v-if="!telHidde">
        <template v-if="telStatus && !telloading">
            <button class="tel-content" v-show="!showWind" @click="showWind = true"
            :class="{'tel-content_active': answeredCollection.settings.collection.length }"
            >
                <div class="tel-content__notif">
                    <div class="tel-content__counter" v-if="contactCollection.settings.collection.length">
                        {{ contactCollection.settings.collection.length }}
                    </div>
                    <div class="tel-content__timer" v-if="timerTime">
                        {{ timerTime | formatTime }}
                    </div>
                </div>

                <svg aria-hidden="true" width="24" height="24">
                    <use href="#icon-phone-2"></use>
                </svg>
            </button>
            
            <v-tel-wind 
                v-show="showWind"
                @action="handleAction"
                @close="showWind = false" 
                :agentName="agentName" 
                ref ="telWind" 
                @dtmf="handleDTMF"
                :callList="contactCollection.settings.collection"
                :answeredList="answeredCollection.settings.collection"
            />
        </template>
        <div v-else-if="!telStatus && !telloading" class="tel-content tel-content_size_small">
            <button class="tel-content tel-button-close" @click="telHidde = true">
                <svg aria-hidden="true" width="15" height="15">
                    <use href="#icon-close"></use>
                </svg>
            </button>
            {{textError}}
        </div>
        <div v-else class="tel-content tel-preloader">
            <v-preloader class="preloader_type_button" size="full"/>
        </div>
    </div>
</template>

<script>
    import vTelWind from './modules/TelWind'
    import browserTabs from '@mixin/browserTabs'
    import vPreloader from '@components/global/Preloader';

    import tel from './v2/mixin/tel'
    export default {
        name: 'Tel',
        components: {
            vTelWind,
            vPreloader,
        },
        mixins: [tel,browserTabs],
        data() {
            return{
               
                contactCollection: null,
                answeredCollection: null,
                showWind: false,
                timer: null,
                timerTime: 0,
                agentName: '',
                telHidde: false,
            }
        },
        // mixins: [mixJanus, telStatistic, browserTabs],
        // components: {
        //     vTelWind,
        //     vPreloader,
        // },
        filters: {
            formatTime(milliseconds)  {
                // Переводим миллисекунды в секунды и округляем до целого числа
                let totalSeconds = Math.floor(milliseconds);

                // Вычисляем часы, минуты и секунды
                let hours = Math.floor(totalSeconds / 3600);
                let minutes = Math.floor((totalSeconds % 3600) / 60);
                let seconds = totalSeconds % 60;

                // Форматируем значения, добавляя нули перед однозначными числами
                let formattedHours = ('0' + hours).slice(-2);
                let formattedMinutes = ('0' + minutes).slice(-2);
                let formattedSeconds = ('0' + seconds).slice(-2);

                // Собираем итоговую строку в формате "чч:мм:сс"
                let hourse = hours ? `${formattedHours}:` : '';
                let formattedTime = hourse+`${formattedMinutes}:${formattedSeconds}`;

                return formattedTime;
            }
        },
        
        mounted() {
           
        },
        watch: {
            'answeredCollection': {
                handler(newValue) {
                    if(this.answeredCollection.settings.collection.length) {
                        this.startWatch()
                    } else {
                        this.stopWatch()
                    }
                },
                deep: true
            }
        },

        
        methods: {
            startWatch() {
                this.timer = setInterval(() => {
                    const collection = this.answeredCollection.settings.collection
                    const Contact = collection[0]
                    if(!Contact || !Contact.audio) {
                        clearInterval(this.timer)
                        this.timerTime = null        
                        return
                    }
                    this.timerTime = Contact.audio.timer
                }, 1000)
            },
            stopWatch() {
                clearInterval(this.timer)
                this.timerTime = null
            },
            handleAction(event) {
                const action = event.action
                switch(action) {
                    case 'call':
                       this.makeCall(event.phoneNumber)
                       break;
                    case 'accept':
                        this.acceptIncoming(event.item)
                        break;
                    case 'inchangup':
                        this.HangupCall(event)
                        break;
                    // case 'hangup':
                    //     this.vjanusHangup(event.item)
                    //     break;
                    case 'transfer':
                        this.maketransferCall(event)
                        //this.dtmfTransferCall(event)
                        break;
                    case 'muteSwitch': 
                    //     this.vJanusMuteAudio()
                    //     break;
                    case 'tab_call':
                        this.vJanusTabCall(event)
                    break;
                }
            },
            handleDTMF(event) {
                console.log('handleDTMF',event);
            }
        }
    }
</script>

<style lang="scss">
    .tel {
        position: fixed;
        bottom: 20px;
        left: 71px;
        z-index: 1000;
        @media (min-width: 1500px) {
            left: 103px;
        }
    }
    ._test  {
        background-color: #000;
        color: #0f0;
        padding: 5px;
        overflow: hidden;
        width: 1000px;
        height: 1000px;
    }
    .tel-preloader {
        margin: 0;
        padding: 5px;
        .preloader_type_button {
            margin: 0;
        }
    }
    .tel-content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0px;
        width: 60px;
        height: 60px;
        flex-shrink: 0;
        background-color: #fff;
        box-shadow: 0px 4px 12px rgba(58, 65, 70, 0.12), 0px 2px 4px rgba(58, 65, 70, 0.12);
        border-radius: 50%;

        &_size_small {
            font-size: 10px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        &__counter,&__timer { 
            padding-left: 5px;
            padding-right: 5px;
            border-radius: 30px;
            min-width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            background-color: #E94A4A;
        }
        
        &__notif {
            position: absolute;
            top: 0;
            left: 100%;
            display: flex;
            align-items: center;
            gap: 3px;
            margin-left: -20px;
        }

        &__timer { 
            width: auto;
            padding-left: 8px;
            padding-right: 8px;
        }

        &_active {
            background-color: #30C66C;
            color: #fff;
            
        }
        svg {
            fill: currentColor;
        }
    }
    .tel-button {
        &-close{
            position: absolute;

            right: 0;
            top: 0;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            svg {
                width: 10px;
                height: 10px;
            }
        }
    }
</style>