<template>
    <span>
        <v-custom-input
        name="uniqueNavigateSearch"
        class="size_medium navSearch_input" 
        :class="{'navSearch_input-expand': expand}"
        :value="value"
        @input="$emit('input', $event)"
        :stop = true
        placeholder="Введите запрос"
        autocomplete="off"
        icon="#icon-search"
        type="search"
    />
    </span>
</template>

<script>
    import vCustomInput from '@components/global/CustomInput'
    export default {
        props: {
            expand: {
                type: Boolean,
                default: false
            },
            value: {
                type: String,
                default: ''
            }
        },
        components: {
            vCustomInput
        },
    }
</script>

<style lang="scss">
    .navSearch {
        &_input {
            opacity: 1;
            width: 100%;
            max-width: 50px;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            .custom-input__input {
                height: 56px;
                background-color: transparent;
                padding: 0;
                overflow: hidden;
                padding-left: 14px;
                color: #919197;
                border: none;
                border-bottom: 1px solid transparent;
                border-radius: 0;

                svg {
                    margin-right: 12px;
                    fill: currentColor;
                }
                input {
                    padding: 0 14px;
                    padding-left: 0;
                    background-color: transparent;
                    height: 40px;    
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                    color: inherit;
                    &::placeholder {
                        font-weight: 400;
                        font-family: Inter;
                    }
                }
            } 
        }
        &_input-expand {
            max-width: 229px;
            .custom-input__input {
                border-color: #CBCCCF;
            }
        }
    }
</style>