

import req from './request'

const DEFAULT_ENDPOINTS = {
    list: '/push-notification/list',
    reference: '/push-notification/reference',
    notif:'/push-notification',
    getDrivers: '/push-notification/get-drivers',
    deleteDriver: '/push-notification/delete-driver',
    clearSearch: '/push-notification/clear-search',
    getSearch: '/push-notification/get-search',
    send: '/push-notification/send'
}

export default {
    list(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.list, param)
    }, 
    reference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    },
    notif(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.notif, param)
    },
    getDrivers(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.getDrivers, param)
    },
    deleteDriver(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.deleteDriver, param)
    },
    clearSearch(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.clearSearch, param)
    },
    getSearch(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.getSearch, param)
    },
    send(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.send, param)
    }
}