<template>
    <div class="navbar-logo">
        <img :src="logo" class="navbar-logo__img" alt="logo">
        <transition name="expand">
            <div v-if="expand" class="navbar-logo__large-content">
                <img :src="logo_large"  class="navbar-logo__large" alt="logo">
            </div>
        </transition>
    </div>
</template>

<script>
import logo from '@/static/images/logo.svg'
import logo_large from '@/static/images/logo_large.svg'
export default { 
    name:"NavLogo",
    data() {
    return {
      logo: logo,
      logo_large: logo_large
    };
  },
    
    props:{
        expand:{
            type:Boolean,
            default:false
        }
    }
}
</script>

<style lang="scss">
.navbar-logo {
    height: 64px;
    display: flex;
    align-items: center;
    flex: 0 0 64px;

    &__large-content{
        opacity: 1;
        max-width: 90px;
        overflow: hidden;
        img{
            width: 90px;
        }
    }

    &__img {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.expand-enter-active, .expand-leave-active {
    transition: .5s;
}
.expand-enter, .expand-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    max-width: 0;
    padding-left: 0;
    opacity: 0;
}
</style>