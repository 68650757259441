import req from './request'

const DEFAULT_ENDPOINTS = {
    status: '/profiles/personal/status',
    
    profile_index: '/profile/index',
    info: '/profile/info',
    update: '/profile/update',
    reference: '/profile/reference',
    company: '/profile/multi-company',
    
    quickPhrase: '/quick-phrase',
    quickPhraseCategory: '/quick-category',
    quickСategoryList: '/quick-category/categories-list',
    quickPhraseCategories: '/quick-phrase/categories',
    quickPhraseListByCategory:'/quick-category/phrase-by-category',

    callCenterNoAanswer:'/call-center/call/no-answer',
    callCenterStatisticTable:'/call-center',

    reportsDriversRegistration: '/reports/drivers-registration',
    reportsDriversRegistrationDetail: '/reports/drivers-registration/detail',
    reportsChats:'/reports/chats',
    reportsChatsDetail:'/reports/chats/detail',
    reportsChatsCategory: '/reports/chats/category',
    reportsChatsDepartment: '/reports/chats/department',

    reportsCandidates: '/reports/candidates',
    reportsCandidatesDetail: '/reports/candidates/detail',
    reportsSource: '/reports/source',
    reportsSourceDetail: '/reports/source/detail',

    reportsCalls: '/reports/calls',
    reportsCallsDetail: '/reports/calls/detail',
    reportsCallsDepartments: '/reports/calls/category/departments',
    reportsCallsCategory: '/reports/calls/category',

    paymentsSystemWithdraw: '/payments/system/withdraw',
    paymentsSystemWithdrawCard: '/payments/system/withdraw/card',

    reportsAuditTrails: '/reports/audit-trails',
    reportsApp: '/reports/app',

    telephonyInfo: '/profile/telephony-info',
    reportsHiringReference: '/reports/hiring/reference',
    companyInfo: '/profile/company-info'
}

export default {

    company(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.company, param)
    },
    status(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.status, param)
    },
    quickPhrase(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.quickPhrase, param)
    },
    info(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.info, param)
    },
    update(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.update, param)
    },
    country(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    },
    get(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.profile_index, param)
    },
    quickPhraseCategory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.quickPhraseCategory, param)
    },
    quickСategoryList(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.quickСategoryList, param)
    },
    quickPhraseCategories(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.quickPhraseCategories, param)
    },
    quickPhraseListByCategory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.quickPhraseListByCategory, param)
    },
    callCenterNoAanswer(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.callCenterNoAanswer, param)
    },
    callCenterStatisticTable(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.callCenterStatisticTable, param)
    },
    reportsDriversRegistration(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsDriversRegistration, param)
    },
    reportsDriversRegistrationDetail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsDriversRegistrationDetail, param)
    },
    reportsChats(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsChats, param)
    },
    reportsChatsDetail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsChatsDetail, param)
    },
    paymentsSystemWithdraw(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentsSystemWithdraw, param)
    }, 
    paymentsSystemWithdrawCard(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.paymentsSystemWithdrawCard, param)
    },
    reportsChatsCategory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsChatsCategory, param)
    },
    reportsChatsDepartment(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsChatsDepartment, param)
    },
    reportsCandidates(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsCandidates, param)
    },
    reportsCandidatesDetail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsCandidatesDetail, param)
    },
    reportsSource(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsSource, param)
    },
    reportsSourceDetail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsSourceDetail, param)
    },
    reportsCalls(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsCalls, param)
    },
    reportsCallsDetail(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsCallsDetail, param)
    },
    reportsCallsDepartments(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsCallsDepartments, param)
    },
    reportsCallsCategory(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsCallsCategory, param)
    },
    reportsAuditTrails(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsAuditTrails, param)
    },
    reportsApp(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsApp, param)
    },
    telephonyInfo(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.telephonyInfo, param)
    },
    reportsHiringReference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reportsHiringReference, param)
    },
    companyInfo(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.companyInfo, param)
    }
}