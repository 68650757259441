<template>
    <div class="sideBar" ref="draggableContainer" @mousedown="handlePositionChange($event)" v-click-outside="(e)=>{
                if(this.outsideDisabed && !e.shiftKey) { return }
                this.close(false)
            }" 
            :class="{
                'sideBar_type_active':show,
                'sideBar_type_draggaable': !isPin
            }"
        >
        <template v-if="!isPin">
            <div class="draggable__border draggable__border-top" @mousedown.stop="handleResizeUp($event)"></div>
            <div class="draggable__border draggable__border-right" @mousedown.stop="handleResizeRight($event)"></div>
            <div class="draggable__border draggable__border-bottom" @mousedown.stop="handleResizeDown($event)"></div>
            <div class="draggable__border draggable__border-left" @mousedown.stop="handleResizeLeft($event)"></div>
            <div class="draggable__coner draggable__coner-top_left" @mousedown.stop="handleResizeTopLeft($event)"></div>
            <div class="draggable__coner draggable__coner-top_right" @mousedown.stop="handleResizeTopRight($event)"></div>
            <div class="draggable__coner draggable__coner-bottom_left" @mousedown.stop="handleResizeBottomLeft($event)"></div>
            <div class="draggable__coner draggable__coner-bottom_right" @mousedown.stop="handleResizeBottomRight($event)"></div>
        </template>
        <div class="sideBar__wrapper" >
            <div class="sideBar__container" ref="draggableContent">
                <div class="sideBar__header">
                    <slot name="header">
                    </slot>
                    <div class="sideBar__tools">
                        <button v-if="draggable" class="button sideBar__button sideBar__button-pin button_with_icon button_type_icon" 
                            @click="handleTogglePin">
                            <svg aria-hidden="true" width="20" height="20" v-if="isPin">
                                <use href="#icon-win-unpin"></use>
                            </svg>
                            <svg aria-hidden="true" width="20" height="20" v-else>
                                <use href="#icon-win-pin"></use>
                            </svg>
                        </button>
                        <button class="button sideBar__button button_with_icon button_type_icon" @click="hendleCloseClick">
                            <svg aria-hidden="true" width="20" height="20">
                                <use href="#icon-close"></use>
                            </svg>
                        </button>
                    </div>
                </div>

                <div class="sideBar__body" @mousedown.stop>
                    <slot name="body">
                    </slot> 
                </div>

                <div class="sideBar__footer" @mousedown.stop v-if="!disabledFooter">
                    <slot name="footer">
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>


<script> 
import mixinDraggable from './draggable.js'
import { mapActions } from 'vuex';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        outsideDisabed: {
            type: Boolean,
            default: false
        },
        draggable: {
            type: Boolean,
            default: false
        },
        disabledFooter: {
            type: Boolean,
            default: false
        }
    },
    mixins: [mixinDraggable],
    data () {
        return {
            isPin: true,
        }
    },
    mounted() {
        this.setSwipeContent();
        document.addEventListener('keydown', (event) => {
            if (event.keyCode === 27) {
                this.close(true)
                this.isPin = true
                this.resetPosition()
            }
        });
        window.addEventListener('resize', 
            this.setSwipeContent
        );
        
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.setSwipeContent);
        this.$emit('hide',true)
        this.close()
    },
    methods: {
        ...mapActions(['TOPBAR_SET_PADDINGRIGHT']),
        close(value) {
            this.$emit('hide',value)
            this.clearSwipeContent()
        },
        clearSwipeContent() {
            const content = document.querySelector('.container > .content ')
            if(!content) {
                return
            }
            content.style.paddingRight = null
            this.TOPBAR_SET_PADDINGRIGHT('')
            setTimeout(() => {
                content.style.transition = null    
            }, 500);
        },
        setSwipeContent() {
            if(!this.show) {
                this.clearSwipeContent()
                return
            }
            
            const width = 420
            const content = document.querySelector('.container > .content ')
            const marginLeft = parseInt(window.getComputedStyle(content).marginLeft)
            const padd = (width + 15 - marginLeft)
            this.TOPBAR_SET_PADDINGRIGHT(padd)
            
            if(padd < 0) {
                this.clearSwipeContent()
                return
            }
            content.style.transition = '.3s'
            content.style.paddingRight = padd +'px'
        },
        hendleCloseClick() {
            this.$emit('hide')
            this.isPin = true
            this.refreshPosition()
            this.close()
        },
        handleTogglePin() {
            this.isPin = !this.isPin
            this.refreshPosition()
            this.$emit('pin', this.isPin)
        },
        refreshPosition() {
            if(this.isPin) {
                this.resetPosition()
                this.setSwipeContent();
            } else {
                this.setDynamicPosition()
                this.clearSwipeContent()
            }
        }, 
        resetPosition() {
            const draggableContainer = this.draggableContainer
            draggableContainer.classList.remove('sideBar_type_draggaable')
            

            this.$nextTick(() => {
                draggableContainer.style.left = (document.querySelector('body').clientWidth - 420) +'px'
                draggableContainer.style.top = '63px'
                draggableContainer.style.height = (document.querySelector('body').clientHeight - 63) +'px'
                draggableContainer.style.width = '420px'
                
                setTimeout(() => {
                    draggableContainer.style.left = null
                    draggableContainer.style.top = null
                    draggableContainer.style.height = null
                    draggableContainer.style.width = null
                    draggableContainer.style.zIndex = null
                }, 400);
            })
        },
        setDynamicPosition() {
            
            const draggableContainer = this.$refs.draggableContainer
            const rect = draggableContainer.getBoundingClientRect()
            draggableContainer.style.left = rect.left + 'px'
            draggableContainer.style.top = rect.top + 'px'
            draggableContainer.style.width = rect.width + 'px'
            draggableContainer.style.zIndex = 1000


            this.$nextTick(() => {
                const draggableContent = this.$refs.draggableContent
                const contRect = draggableContent.getBoundingClientRect()
                draggableContainer.style.height = contRect.height+64 + 'px'
            })
        },
    }

}
</script>

<style lang="scss">
@import '../../../../gulp-src/app/scss/_variables';
@import '../../../../gulp-src/app/scss/_mixins';

.sideBar {
    $width:420px;
    position: fixed;
    top: $logo-height+8px;
    bottom: 0;
    //right: -$width;
    right: 0;
    display: flex;
    z-index: 99;
    left: auto;
    overflow: hidden;
    transition: .2s;
    box-shadow: 0 0 0 rgba(58, 65, 70, 0.12), 0 0 0 rgba(58, 65, 70, 0.06);


    &_type{
        &_active {
            box-shadow: -8px 0 16px rgba(58, 65, 70, 0.12), -4px 0 12px rgba(58, 65, 70, 0.06);
            right: 0;
        }
        &_draggaable {
            transition: .0s;
            border-radius: 16px;
            border: 1px solid #CBCCCF;
            box-shadow: 0px 4px 8px rgba(58, 65, 70, 0.06), 0px 8px 24px rgba(58, 65, 70, 0.06);

            cursor: move;
            .sideBar__wrapper {
                width: 100%;
                border-radius: 16px;
            }
        }
    }

    &__wrapper{
        width: $width;
        background-color: #fff;
        display: flex;
        padding: 16px;
        @include scrollbar();
    }

    &__header,
    &__body,
    &__footer {
        margin-bottom: 16px;
    }

    &__footer {
        margin-top: auto;
        margin-bottom: 0;
        padding-bottom: 16px;
    }

    &__header{
        display: flex;
    }

    &__header p {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #0A0A17;
    }

    &__body {
        height: 100%;
        //max-height: calc(100% - 92px);
        max-height: calc(100% - 72px);
    }

    &__container {
        width: 100%;
        position: relative;
        z-index: 2;
        cursor: default;
        display: flex;
        flex-direction: column;
    }
    &__tools {
        margin-left: auto;
        display: flex;
        flex-wrap: nowrap;
        margin-top: auto;
        margin-bottom: auto;
    }
    & &__button {
        width: 20px;
        height: 20px;
        color: #919197;
        margin-right: 20px;
        &-pin {
            color: #3A4146;
        }
        &:last-child{
            margin-right: 0;
        }
        svg {
            margin-left: 0;
            margin-right: 0;
            fill: currentColor;
        }
    }
}

.sideSwipe {
    &-enter-active, &-leave-active {
        transition: all 1s;
    }
    &-enter, &-leave-to{
        margin-left: 100%;
    }
    &-move {
        transition: all 1s;
    }
}

.draggable {
  &__border {
    position: absolute;
    z-index: 3;
    
    &-top,&-bottom {
      width: 100%;
      height: 10px;
      cursor: n-resize;
    }
    &-bottom {
      bottom: 0;
    }
    
    &-left,&-right {
      width: 10px;
      height: 100%;
      cursor: w-resize;
    }
    &-right {
      right: 0;
    }
  }
  &__coner{
    width: 20px;
    height: 20px;
    z-index: 3;
    position: absolute;
    &-top_right, &-bottom_left {
        cursor: ne-resize;
    }
    &-top_left, &-bottom_right{
        cursor: nw-resize;
    }
    &-top_left {
        top: 0;
        left: 0;
    }
    &-top_right {
        top: 0;
        right: 0;
        
    }
    &-bottom_left {
        bottom: 0;
        left: 0;
    }
    &-bottom_right {
        bottom: 0;
        right: 0;
    }
  }
}

</style>