import api from '@api'
import janus from '../js/janus'
import Contact from '../js/contact'
import contactCollection from '../js/contactCollection'
import answeredCollection from '../js/answerdCollections'
import callingCollection from '../js/callingCollection'


export default {
    data () {
        return {
            settings: null,
            janus: null,
            tabIsHidden: false,
            isNoising: false,

            telStatus: false,
            telloading: true,
            textError: false,
        }
    },
    mounted () {
        document.addEventListener("visibilitychange", function() {
            if (!document.hidden) {
                this.stopBlinkingTitle();
            }
        }.bind(this));
    },

    methods: {
        //todo: обработать forbidden, нгевернівій лог\пфасс
        initTel() {
            this.getSettings().then(janusSettings=>{
                this.janus = new janus({
                    telSettings: janusSettings,
                    emitMessage: this.onmessage,
                    addAudio: this.addAudio,
                    registrationFailed: this.registrationFailed,
                    registered: this.registered
                })
                this.contactCollection = new contactCollection()
                this.answeredCollection = new answeredCollection()
                this.callingCollection = new callingCollection()
                this.agentName = janusSettings.id
            })
        },
        registered() {
            this.telStatus = true
            this.telloading = false
        },
        registrationFailed(reason) {
            this.telStatus = false
            this.telloading = false
            this.textError = reason
        },
        onmessage(action, plugin, janinfo) {
            console.log(action, plugin, janinfo);
            switch (action) {
                case "incomingcall":
                    this.handleIncomingCall(plugin, janinfo)
                    console.log('handleIncomingCall', {plugin});
                    break;
                case 'calling': 
                    this.handleCalling(plugin, janinfo)
                    break;
                case "hangup":
                    this.handleHangup(janinfo)
                    break;
                case 'accepted':
                    this.handleAccepted(plugin, janinfo)
                    break;
                default:
                    break;
            }
        },

        async makeCall(value) {
            const company_id = await this.getCurrentCompanyId()
            const newcalling = await this.janus.makeCall(company_id+'*'+value)
            console.log('makeCall', company_id+'*'+value)
            this.callingCollection.add(newcalling)
        },

        getCurrentCompanyId() {
            return api.profile.companyInfo().then(res => {
                return res.response.company_id
            }).catch(error=>{
                if(error.message) this.toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                this.isloading = false
            })
        },

        handleIncomingCall(plugin, janinfo) {
            // plugin = new Date().getTime()
            // janinfo.msg = {
            //     "sip": "event",
            //     "result": {
            //         "event": "incomingcall",
            //         //"username": "sip:+77782503592@89.184.65.45",
            //         //"displayname": "\"Kazakhstan\"",
            //         "call_id": "4f1942f0405445b05af9fa5b2794e9c0@89.184.65.45:5060",
            //         "username": "sip:+380977000006@89.184.65.45",
            //         "displayname": "\"Alga\"",
            //         "callee": "sip:417@167.235.51.139:46817;transport=udp"
            //     },
            //     "call_id": "4f1942f0405445b05af9fa5b2794e9c0@89.184.65.45:5060"
            // }
            // console.log('result:', janinfo.msg.result);
            // const randomPart = Math.floor(100000 + Math.random() * 900000);
            // const fake = {
            //     result: {
            //         "username": `sip:+380977${randomPart}@89.184.65.45`,
            //         "displayname": randomPart /2 < 450000 ? "\"Kazakhstan\"" : "\"Alga\""
            //     }
            // }
            // const fake = {
            //     result: {
            //         "username": `sip:+380977${randomPart}@89.184.65.45`,
            //         //"username" : `sip:+77070696252@89.184.65.45`,
            //         "displayname": "\"Alga\""
            //     }
            // }
            // janinfo.msg.result = { ...janinfo.msg.result,...fake.result }
            const contact = new Contact({
                plugin, janinfo, janus: this.janus
            })

            this.contactCollection.add(contact)

            console.log({contactCollection: this.contactCollection});

            this.alarmNewCall()
            this.blinkingTitle()

        },

        handleCalling(plugin, janinfo) {
            console.log('handleCalling', plugin, janinfo );
            const callingContact = this.callingCollection.getById(plugin.id)
            janinfo.msg.result= {
                "event": "calling",
                "username": `sip:${callingContact.phoneNumber}@89.184.65.45`,
            }
            const contact = new Contact({
                plugin, janinfo, janus: this.janus
            })
            contact.client.name = 'Набранный номер'
            //this.contactCollection.add(contact)

            this.answeredCollection.add(contact)
            this.callingCollection.remove(plugin.id)
        },

        handleHangup(janinfo) {
            this.contactCollection.remove(janinfo.msg.call_id)
            this.answeredCollection.deleteByCallId(janinfo.msg.call_id)
        },

        getSettings() {
            return api.profile.info().then(res=>{
                return res.response.telephony_info
            }).catch(error=>{
                this.$toast.error(error.message)
                return error
            }).finally(e=>{
                this.isloading = false
            })
        },

        acceptIncoming(contact) {
            this.janus.acceptIncomingCall(contact.plugin, contact.janinfo.jsep)
            this.contactCollection.deleteByCallId(contact.janinfo.msg.call_id)
            this.answeredCollection.add(contact)
        },

        handleAccepted(plugin) {
            const contact = this.answeredCollection.getByPluginId(plugin.id)
            contact.accepted = true
            if(this.answeredCollection.length > 1) {
                contact.muteAudio()
            }
        },

        addAudio(id, audio) {
            this.answeredCollection.pushAudio(id, audio)
        },

        async HangupCall(contact) {
            await this.janus.makehangup(contact.item.plugin)
            this.contactCollection.deleteByCallId(contact.item.call_id)
            this.handleHangup(contact.item.janinfo)
        },

        alarmNewCall() {
            const canMakeNoice = this.canMakeNoice()
            console.log('canMakeNoice', canMakeNoice);
            if(canMakeNoice) {
                this.makeNoice()     
            }
        },
        canMakeNoice() {
            return document.hidden && !this.answeredCollection.length 
        },
        makeNoice() {
            if('makeNoice', this.isNoising) return
            const sound = new Audio('/sound/ring.mp3');
            sound.volume = 0.13;
            this.isNoising = true;
            sound.play();
            sound.addEventListener('ended', function() {
                this.isNoising = false;
            });
        },

        blinkingTitle() {
            if(document.hidden) {
                this.startBlinkingTitle()
            }
        },

        startBlinkingTitle() {
            const favicon = document.querySelector('link[rel="icon"]')
            if(!favicon.dataset.dafaultFavicon) favicon.dataset.dafaultFavicon = favicon.href
            if(this.interval) return
            this.interval = setInterval(() => {
                const counter = this.answeredCollection.length
                const digitFavicon = "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100'%3E%3Ccircle cx='50' cy='50' r='40' fill='%23FF7F7F'/%3E%3Ctext x='50' y='58' font-weight='800' font-family='Arial, sans-serif' font-size='46' fill='white' text-anchor='middle'%3E"+counter+"%3C/text%3E%3C/svg%3E"
                if (document.title === this.$route.meta.title) {
                  document.title = 'Входящий вызов: ' + counter;
                  favicon.href = digitFavicon
                } else {
                  document.title = this.$route.meta.title;
                  favicon.href = favicon.dataset.dafaultFavicon
                }

                if(!counter){
                    this.stopBlinkingTitle()
                }
            }, 1000);
        },

        stopBlinkingTitle() {
            const favicon = document.querySelector('link[rel="icon"]')
            clearInterval(this.interval);
            this.interval = null;
            document.title = this.$route.meta.title;
            favicon.href = favicon.dataset.dafaultFavicon
        },

        handleTabCalling(data) {
            this.makeCall(data.phoneNumber)
        },
        async maketransferCall(item) {
            const company_id = await this.getCurrentCompanyId()
            item.number = company_id+'*'+this.agentName+'*'+item.number 
            const newcalling = await this.janus.makeTransfe(item)
            console.log('maketransferCall', item, newcalling)
        },
        async dtmfTransferCall(item) {
            const company_id = await this.getCurrentCompanyId()
            item.number = '*2'+company_id+'*'+item.number
            const newdtmf = await this.janus.makeDtmfTransfer(item)
        }
    }
}