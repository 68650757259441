import axios from 'axios'
import prefixedUrl from './prefixedUrl'

export default function (config = {}) {
    window.axios = axios.create(config)
    window.axios.interceptors.request.use(config => {
        return {
            ...config,
            url: prefixedUrl(config.url)
        }
    })

    window.axios.interceptors.response.use(
        res => res,
        err => {
            return Promise.reject(err.response ? err.response.data || err : err)
        }
    )

    return window.axios
}