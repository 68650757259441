import Vue from 'vue';

export default class ContactCollection{
    constructor(options) {
        this.cache(options)
        this.init()
    }
                
    cache(options) {
        this.settings = Vue.observable({
            ...this.defaults,
            ...options
        });

        return this
    }
                
    get defaults() {
        return {
            collection: [],
            timers: []
        }
    }
                
    init() {
        this.events()
    }
    events() {
                
    }

    add (item) {
        const isInList = this.checkDouble(item)
        
        if(!isInList) {
            this.settings.collection.push(item)
            item.fillInfo()
        }
    }
    getByPluginId(id) {
        return this.settings.collection.find(item => item.plugin.id == id)
    }

    checkDouble(item) {
        const id = item.id
        const number = item.number
        return this.settings.collection.some((listItem, index)=> {
            if( listItem.id == id || listItem.number == number )  {
                this.cancleRemovedItem(item)
                listItem.update(item)
                return true
            }
        })
    }

    remove (id) {
        var r = this.settings.collection.some((listItem, index)=> {
            if( listItem.id == id )  {
                listItem.setOnRemove()
                this.setOnRemove(listItem,index)
                return listItem.id == id
            }
        })
    }

    delete (item) {
        this.settings.collection = this.settings.collection.filter(listitem => listitem.number != item.number);
    }
    deleteByCallId (callId) {
        //this.answeredCollection.settings.collection[0].settings.janinfo.msg.call_id
        this.settings.collection = this.settings.collection.filter(listitem => listitem.callId != callId);
    }

    setOnRemove(item, index) {
        item.setOnRemove()
        const timerId = item.number
        const timer = setTimeout(() => {
            // this.settings.collection.splice(index, 1)
            // delete this.settings.collection[index]
            this.settings.collection = this.settings.collection.filter(listitem => listitem.number != item.number);
            item.remove()
            this.timerRemove(item.number)
        }, 5000)
        this.timers = {
            timer,
            timerId
        }
    }

    timerRemove(id) {
        this.timers = this.timers.filter(timer => timer.timerId != id)
    }

    cancleRemovedItem(item) {
        this.timers.forEach((timer, index)=>{
            if(timer.timerId == item.number) {
                clearTimeout(timer.timer)
                this.timers.splice(index, 1)
            }
        })
    }

    set timers(value) {
        this.settings.timers.push(value )
    }

    get timers() {
        return this.settings.timers
    }

    get length() {
        return this.settings.collection.length
    }
}