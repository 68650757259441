import req from './request'

const DEFAULT_ENDPOINTS = {
    get: '/overdraft',
    calculate: '/overdraft/calculate',
    fullIndex: '/full-overdraft',
    references: '/full-overdraft/reference'
}

export default {
    
    getOverdraft(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.get, param)
    },

    calculate(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.calculate, param)
    },

    fullIndex(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.fullIndex, param)
    },
    references(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.references, param)
    }
}