<template>
    <div class="custom-input" :class="{ 
        'custom-input_type_error':error || (customError && value &&  ''+value.length && (''+value < minValue || ''+value > maxValue)),
        'custom-input_type_focus':isFocus,
        'custom-input_type_file': type === 'file',
        'disabled': disabled
    }">
        <label :for="name" v-if="label" class="custom-input__label">
            {{label}}
        </label>
        <div class="custom-input__input"  @click="handleDivClick">
            <div class="custom-input__icon" v-if="icon" style="margin-right:5px" >
                <svg aria-hidden="true" width="20" height="20">
                    <use :href="icon"></use>
                </svg>
            </div>
            <input 
                v-if="input"
                ref="input"
                v-show="type !== 'file'"
                :type="type" 
                :name="name"
                :value="value"
                v-mask="mask"
                :placeholder="placeholder"
                :disabled="disabled"
                v-on:keypress.ctrl.10.13="handleCtrlEnter()"
                :maxlength="maxlength"
                :accept="accept"
                :autocomplete="autocomplete"
                @click = "handleInputClick"
                @input="handleInput($event)" 
                @keyup="handlekeyup"
                @keydown="handlekeydown"
                @focus="handleFocus"
                @blur="handleblur"
            />
            
            <template v-if="type === 'file'">
                {{ value }}
            </template>
            <v-button type="button" class="custom-input__button button_type_icon"
                v-if="buttonIcon || isload"
                :loading="isload"
                :disabled="disabled || isload"
                :class="[buttonIconClass,{'button_type_static': type != 'file'}]"

                @click="handleClick($event)"
            >
                <svg aria-hidden="true" v-if="buttonIcon" :width="iconSize.w" :height="iconSize.h">
                    <use :href="buttonIcon"></use>
                </svg>
            </v-button>
        </div>
        
        <transition name="grow">
            <div v-if="error" class="validation__label validation__label_type_error">
                {{error || error.message }}
            </div>
        </transition>
        <transition name="grow">
            
            <div v-if="customError && !error && value && ''+value.length && (''+value < minValue || ''+value > maxValue)" class="validation__label validation__label_type_error">
                {{tcustomError}}
            </div>
        </transition>

        <transition name="grow">
            <div v-if="notes" class="validation__label">
                {{notes}}
            </div>
        </transition>
    </div>
</template>

<script>
import vButton from './vButton.vue'
export default {
    name: 'CustomInput',
    components: {
        vButton,
    },
    props: {
        autocomplete: {
            type: [String, Boolean],
            default: false
        },
        accept: {
            type: [String, Boolean],
            default: false
        },
        stop: {
            type:Boolean,
            default:false
        },
        isload: {
            type:Boolean,
            default:false
        },
        notes: {
            type: String,
            default: ''
        }, 
        type: {
            type: String,
            default: 'text'
        }, 
        placeholder: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        value: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        maxlength: {
            type: String,
            default: ''
        },
        iconSize: {
            type: Object,
            default: function() {
                return { w: 20, h: 20 };
            }
        },
        buttonIcon: {
            type: String,
            default: ''
        },
        buttonIconClass: { 
            type: Object,
            default: () => {}
        },
        error: {
            type: [String,Boolean,Object],
            default: () => {}
        },
        mask: {
            type: Object,
            default: () => {}
        },
        minValue: {
            type: [Number,Boolean],
            default: -Infinity
        },
        maxValue: {
            type: [Number,Boolean],
            default: Infinity
        },
        customError: {
            type: [String,Boolean],
            default: false,
        },
    },
    data() {
        return {
            isFocus: false,
            tcustomError: null,
            input: true,
            tElement: null
        }
    },
    mounted() {
        this.updateTCustomError()
    },
    watch: {
        minValue(newValue, oldValue) {
            this.updateTCustomError()
        },
        maxValue(newValue, oldValue) {
            this.updateTCustomError()
        },

        mask(newValue, oldValue) {
            if(newValue.mask == oldValue.mask) return
            this.input = false
            this.$nextTick(() => {
                this.input = true
            })
        },

        disabled(newValue, oldValue) {
            if (newValue === false) {
                this.focusInput(); // Вызываем метод focusInput() при изменении isDisabled на false
            }
        }
    },
    methods: {
        handleDivClick(event) {
            if(this.type == 'file') {
                this.$refs.input.click()
            }
            this.$emit('click')  
        },
        handleInputClick(event) {
            if(this.stop) event.stopPropagation()
        },
        
        handlekeydown(event) {
            if(event.shiftKey || event.ctrlKey) return
            this.$emit('keydown', event.target.value, event)
        },
        handlekeyup(event) { 
            if(event.shiftKey || event.ctrlKey) return
            this.$emit('keyup',  event.target.value, event)
        },
        handleInput(event) {
            if(this.type == 'file') {
                this.$emit('fileUpdate', event.target.files)
            } 
            if(event.shiftKey || event.ctrlKey) return
            this.$emit('input', event.target.value, event)
        },
        handleClick() {
            this.$emit('buttonClick',this.$el)
        },
        handleCtrlEnter(event) {
            const input = this.$el,
                form = input.closest('form, .form'),
                submit = form.querySelector('[type="submit"]')
            if(submit) submit.click()
        },
        updateTCustomError(){
            if(!this.customError) return 
            const {minValue , maxValue} = this.$props
            this.tcustomError = this.customError.replace('${minValue}', minValue).replace('${maxValue}', maxValue)  
        },

        focusInput() {
            if (this.tElement) {
                this.$nextTick(() => {
                    this.tElement.focus()
                });
            }
        },
        handleFocus(e) {
            this.isFocus = true; 
            this.tElement = e.target
            this.$emit('focus', e) 
        },
        handleblur(e) {
            this.isFocus = false; 
            if(this.disabled) return
            this.tElement = null
            this.$emit('blur', e) 
        }
    },
}
</script>

<style lang="scss">
    .custom-input {
        color: #919197;
        &__input {
            display: flex;
            align-items: center;
            width: 100%;
            min-width: 1px;
            padding-right: 16px;
            padding-left: 16px;
            position: relative;
            line-height: 24px;
            background: #FFFFFF;
            border: 1px solid #CBCCCF;
            border-radius: 6px;
            outline: none;
            appearance: none;

            input {
                font-size: 16px;
                width: 100%;
                color: #3A4146;

                &[type="number"]::-webkit-inner-spin-button,
                &[type="number"]::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                &[type="number"] {
                    -moz-appearance: textfield; /* Firefox */
                }
            }
            .button {
                // чтобы иконки в форме быстрых выплат выравнять
                margin-left: 8px;
                margin-right: 8px!important;
            }
        }
        &__icon {
            display: flex;
            flex-grow: 0;
        }

        &__label {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #919197;
            margin-bottom: 8px;
            display: inline-block;
        }

        &_type {
            &_focus .custom-input__input {
                border-color:#3A4146;
            }
            &_error .custom-input__input {
                border-color: #E94A4A
            }

            &_file {
                .button {
                    margin-left: auto;
                }
            }
        }


        &.size {

            &_small .custom-input__input {
                height: 32px;
                max-height: 32px;
            }

            &_medium .custom-input__input {
                height: 40px;
                max-height: 40px;
            }

            &_large .custom-input__input {
                height: 56px;
                max-height: 56px;
            }
        }
        &.width{
            &__full {
                width: 100%;
            }
        }

        &.on-focus {
            border-color: #3A4146;
        }

        &.disabled &__input {
            opacity: .8;
            &, input {
                background-color: #e9eaee;
            }
        }


        .grow {
            &-enter-active, &-leave-active {
                transition: .5s;
            }

            &-enter, &-leave-to {
                max-height: 0;
                opacity: 0;
            }
        }

        &__button {
            span { 
                color: currentColor;
            }
            svg { 
                fill: currentColor;
            }
        }
    }
</style>