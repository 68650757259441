import req from './request'

const DEFAULT_ENDPOINTS = {
    url: '/reference'
}

export default {
    get(_data,url) {
        const endpoint = url || DEFAULT_ENDPOINTS.url
        return req['get'](_data,endpoint, '')
    }
}