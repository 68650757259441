<template>
    <div class="nav-submenu" v-if="item.label.toLowerCase().includes( search.toLowerCase() ) || getSAearch()">
        <button class="nav-submenu__button button button_with_icon button_size_large"
            @click.stop="handleClick"
            :class="{
                'button_color_gray-84':!isActive,
                'button_color_yellow':isActive,
                'is-open':(isActive || open) && expand
            }"
        >
            <svg class="nav-submenu__svg" aria-hidden=true width="20" height="20">
                <use :href="item.icon"></use>
            </svg>
            <transition name="expand">
                <div class="nav-submenu__text" v-if="expand">
                    <span> {{item.label}} </span>

                    <svg :class="{'is-open':(isActive || open || search && getSAearch()) && expand}" aria-hidden=true width="12px" height="12px">
                        <use :href="'#icon-arrow-right'"></use>
                    </svg>

                </div>
            </transition>
        </button>
        <transition name="grow">
            <div class="nav-submenu__body" v-if="(isActive || open || search && getSAearch()) && expand">
                <ul class="nav-submenu__list">
                    <li class="nav-submenu__item" v-for="(submenu,index) in item.items" :key="index" v-if ="submenu.label.toLowerCase().includes( search.toLowerCase() ) ">
                        <router-link class="nav-submenu__link"
                            :class="{'active':($route.path == submenu.link+'/'|| isActiveSubmenu(submenu))}"
                            :to="submenu.link"
                            @click.native="$emit('routeClick')"
                        >
                            {{submenu.label}}
                        </router-link>
                    </li>
                </ul>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'NavSubMenu',
    props: {
        search: {
            type: String,
            default: ''
        },
        expand: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: () => {}
        },
        current: {
            type:String,
            default:'/'
        }
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        handleClick() {
            this.open = !this.open
            this.$emit('click')
        },
        getSAearch() {
            return this.item.items.some(submenu=>submenu.label.toLowerCase().includes( this.search.toLowerCase() ))

        },
        isActiveSubmenu(submenu) {
            let ret = false
            const parentRoute = this.$route.matched[0]
            const altActive = parentRoute.meta.altNavSubMenuActive
            ret = submenu.link == altActive || submenu.link == parentRoute.path.replace('/(uk/|ru/)?','')
            return ret
        }
    },
    computed:{
        isActive(){
            return this.item.icon == this.$route.meta.activeSideIcon || this.item.items.some(submenu=>{
                return submenu.link == this.$route.path
            })
        },
    }
}
</script>

<style lang="scss">
.navbar-nav__item .nav-submenu {
    position: relative;

    &__button {
        height: 50px;
        padding: 14px;
        display: flex;
        border-radius: 6px;
        justify-content: flex-start;
        text-align: left;

        &.is-open {
            color: #fff;
        }
    }

    &__svg {
        margin-left: 0;
        margin-right: 0;
    }

    &__text {
        max-width: 180px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: left;
        opacity: 1;
        overflow: hidden;

        span {
            padding-left: 15px;
            display: inline-block;
            width: 180px; 
        }

         svg {
            transition: .3s;
            &.is-open {
                transform: rotate(90deg);
            }
        }
    }

    &__body{
        max-height: 500px;
        overflow: hidden;
    }

    &__list {
        display: flex;
        flex-direction: column;
    }

    &__item {
        padding: 14px;
        max-width: 230px;
        display: inline-block;
    }

    &__link {
        color: #fff;

        // &:hover, &.active {
        //     color:#F9C43E
        // }
        &.router-link-exact-active, &.router-link-active, &.active{
            color:#F9C43E
        }
    }
    
    &__button {
        margin-right: 0;
        &.button_color_yellow:not(&:hover){
            color: #333;
        }
    }
    .grow-enter-active, .grow-leave-active {
        transition: .5s;
    }
    .grow-enter, .grow-leave-to {
        max-height: 0;
        opacity: 0;
    }
} 
</style>