import apiClient from './apiClient'
import qs from 'qs'
// import { COMMON } from './token' 
const authRquest = apiClient({
    paramsSerializer: params => qs.stringify(params),
    validateStatus: function (status) {
        if(status == 401) { 
            const path = '/login'
            window.axios.defaults.headers.common = {}
            localStorage.removeItem('common')
            if(window.location.pathname != path ) window.location.href = '/login'
            return false 
        }
        if(status > 200) { return false }
        return status == 200; // Resolve only if the status code is less than 500
    }
})
window.common  = authRquest
export default authRquest