import req from './request'

const DEFAULT_ENDPOINTS = {
    tp: '/financial/task-pool', // GET {{api_url}}/financial/task-pool - для вкладки (основной) "Задачи"
    tpHistory: '/financial/task-pool/history', // {{api_url}}/financial/task-pool/history// для вкладки "История" GET {{api_url}}/financial/task-pool/history - для вкладки "История"
    tpStep: '/financial/task-pool/to-next-step', // {{api_url}}/financial/task-pool/to-next-step/{:id} // Для перехода задачи в следующий статус PUT {{api_url}}/financial/task-pool/to-next-step/{:id}  - Для перехода задачи в следующий статус
}

export default {
    tp(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.tp, param)
    },
    tpHistory(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.tpHistory, param)
    },
    tpStep(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.tpStep, param)
    }
}