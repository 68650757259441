import references from '../../store/modules/references'
import req from './request'

const DEFAULT_ENDPOINTS = {
    report: '/terminals/report/index',
    failed: '/terminals/report/failed',
    config: '/terminals/report/list-config',
    setting: '/terminals/config',
    reference: '/terminals/config/reference',
    

}

export default {
    report(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.report, param)
    },
    config(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.config, param)
    },
    failed(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.failed, param)
    },
    setting(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.setting, param)
    },
    reference(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.reference, param)
    }
}