import req from './request'

const DEFAULT_ENDPOINTS = {
    create:'/transactions/base',
    category: '/transactions/category',
    mass:  '/transactions/mass/default',
    references: '/transactions/mass/default/reference',
    retrieveCategory:'/transactions/mass/default/retrieve-category',
    import: '/transactions/mass/default/import',
    drivers: '/transactions/mass/driver',
    transaction: '/transactions/mass/driver/execute-transaction',
    transactionStatus: '/transactions/mass/default/status',
    execute: '/transactions/mass/default/execute',
    statements: '/transactions/statement',
    statementParks: '/transactions/statement/parks',
    bonusStatements: '/transactions/bonus/statements',
    statement: '/transactions/statement/list',
    checkToPay: '/transactions/mass/default/check-to-pay'
}

export default {
    create(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.create, param)
    },
    statements(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.statements, param)
    },
    mass(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.mass, param)
    },
    category(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.category, param)
    },
    references(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.references, param)
    },
    retrieveCategory(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.retrieveCategory, param)
    },
    import(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.import, param)
    },
    drivers(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.drivers, param)
    },
    transaction(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.transaction, param)
    },
    transactionStatus(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.transactionStatus, param)
    },
    execute(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.execute, param)
    },
    statementParks(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.statementParks, param)
    },
    bonusStatements(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.bonusStatements, param)
    },
    statement(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.statement, param)
    },
    checkToPay(payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.checkToPay, param)
    }
}