import api from '@api'
import { eventBus } from '@eventBus'
export default {
    data() {
        return {
            candidate: null
        }
    },
    methods: {
        receiveUUid(uuid) {
            if(!this.candidate) return
            const payload = {
                candidate_id: this.candidate.candidate_info.id,
                call_id: uuid
            }
            if(!this.candidate || !uuid)  return

            api.candidats.assetCall(payload, '', 'post').then().finally(e=>{
                this.candidate = null
            })
        },  

        getRoute(contact) {
            const payload = {
                customer: contact.number    
            }
            return api.chat.telephonySearch(payload,'','post').then(res=>{
                const {type} = res.response
                if(!type) {
                    this.$toast.error(res.response.message)
                    return
                }

                if(type == 'driver') { 
                    return this.getRouteOnDriver(res.response)
                } else { 
                    this.candidateAssetCall({contact, candidate_info: res.response.candidate_info})
                    return this.getRouteOnCandidate(res.response)
                }
            }).catch(error=>{
                if(error.message) this.$toast.error(error.message)
                console.error(error)
            }).finally(e=>{
                this.isloading = false
            })
            
        },
        candidateAssetCall(data) {
            const {contact, candidate_info} = data
            if(candidate_info && candidate_info.is_create) {
                eventBus.$emit('candidateAssetCall', {contact, candidate_info})
            }  
        },
        
        getRouteOnDriver(driver) {
            const {park_id,id} = driver.driver_info
            return {name: "DriverDetail", query: { driver_id:id, park_id}}
        },
        getRouteOnCandidate(candidate) {
            const {id} = candidate.candidate_info
            this.candidate = candidate
            return {name: 'CandidateEdit', query: {id}}    
        },
        handleAcceptIncCall(item) {
            const company = item.company.settings || {}
            const contact = item
            if (company.can_change_company) {
                this.changeCompany(company).then(res => {
                    this.refresh = false
                    this.$nextTick(() => {
                        this.refresh = true
                        if(contact) {
                            this.getRoute(item).then(routeOnDriver=>{
                                this.$router.replace({ query: {} });
                                if(this.IsSame(routeOnDriver)) return
                                this.$router.push(routeOnDriver)
                            })
                        }
                    })
                    eventBus.$emit('companyIsChanget');
                })
            }
            else {
                this.getRoute(contact).then(routeOnDriver=>{
                    if(!routeOnDriver) return
                    if(this.IsSame(routeOnDriver)) return
                    this.$router.push(routeOnDriver)
                })
            }
        },
        IsSame(driver) {
            const { _driver_id, _park_id } = driver.query
            const {driver_id, park_id} = this.$route.query
            return _driver_id && _park_id && driver_id && park_id && _driver_id == driver_id && _park_id == park_id
        }
    },
}
