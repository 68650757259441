<template>
    <div class="tel-list">
        <transition-group name="grow-in-out" tag="div" class="tel-list__items" >
            <div v-if="value && value.length"  v-for="item in value" :key="item.id" :id="item.id"
                class="tel-list__item"
                :class = "{'tel-list__item-transferring': item.transferring}"
            >
                 <div class="tel-list__name">
                    <template>
                        {{ item.client.name }}
                    </template>
                    
                    <template v-if="item.company">
                        <svg v-if="item.company.settings && item.company.settings.company_name" width="15" height="15" :style="{marginLeft: '5px'}">
                            <use href="#icon-link"></use>
                        </svg>
                        {{ item.company.name }}
                    </template> 
                </div>
                
                <div class="tel-list__number">
                    {{ item.client.number }} 
                    <template v-if="item.company.cnumber"> {{ item.company.cnumber }} </template>
                    <div class="tel-list__buttons">
                        <button class="tel-list__button-red button button_with_icon button_type_icon button_color_transp-black" :disabled="!item.plugin" 
                        @click="$emit('action', {item, action: 'inchangup'})"
                            :style="{opacity: !item.plugin ? 0.5 : 1}"
                        >
                            <svg aria-hidden="true" width="30" height="30">
                                <use href="#icon-phone-denay-circle"></use>
                            </svg>
                        </button>
                        <button class="tel-list__button button button_with_icon button_type_icon" 
                            :disabled="disabled || !item.plugin " 
                            :style="{opacity: disabled || !item.plugin  ? 0.5 : 1}"
                            
                            @click="$emit('action', {item, action: 'accept'})"
                        >
                            <svg aria-hidden="true" width="30" height="30">
                                <use href="#icon-phone-circle"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="tel-list__timer" v-if="!item.plugin"></div>
            </div>
        </transition-group>
        <p v-if="!value || !value.length">Нет входящих звонков</p> 
    </div>
</template>


<script>
import { mapState, mapActions } from 'vuex'

export default {
    props: {
        value: {
            type: [Array, Boolean],
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapState({
            user: state => state.profile.user,
        }),
    },
}
</script>


