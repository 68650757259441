

import req from './request'

const DEFAULT_ENDPOINTS = {
    chatdetail: '/communications/chat/view',
    chats: '/communications/chat/index',
    history:'/communications/chat/history',
    historyItem: '/communications/chat/history-item',
    chat: '/communications/chat',
    create: '/communications/message/create',
    endcallRefference: '/communications/chat/reference',
    messaageUpdate: '/communications/message/update',
    messages: '/communications/message/index',
    quickPhrase: '/communications/chat/quick-phrases',
    references: '/communications/chat/reference',
    tabCounters: '/communications/chat/count',
    update: '/communications/chat/update',
    chooseCategory: '/communications/telephony/choose-category',
    outgoing:'/communications/telephony/outgoing',
    telephonySearch: '/communications/telephony/search',
    telephonyTreatmentIncomingNumber: '/communications/telephony/treatment-incoming-number',
    choseCategory: '/communications/chat/chose-category'
    
}

export default {
    history(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.history, param)
    },
    getHistyoryItems(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.historyItem, param)  
    },
    getChat(payload, param, method) {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.chat, param)
    },
    outgoing (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.outgoing, param)
    },
    chooseCategory (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.chooseCategory, param)
    },
    endcallRefference (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.endcallRefference, param)
    },
    getChats (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.chats, param)
    },
    getCounts (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.tabCounters, param)
    },
    getChatDetail (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.chatdetail, param)
    },
    getReferences (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.references, param)
    },
    getMessages (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.messages, param)
    },
    update (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.update, param)
    },
    submit (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.create, param)
    },
    messaageUpdate (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.messaageUpdate, param)
    },
    quickPhrase (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.quickPhrase, param)
    },
    telephonySearch (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.telephonySearch, param)
    },
    telephonyTreatmentIncomingNumber (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.telephonyTreatmentIncomingNumber, param)
    },
    choseCategory (payload, param, method)  {
        return req[method || 'get'](payload, DEFAULT_ENDPOINTS.choseCategory, param)
    }


}
